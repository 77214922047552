import Layout from "../components/layout/Layout";
import { useEffect, useState, useRef } from "react";
import classes from "./ShowExperimentPage.module.css";
import {
  useParams,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { IExperiment } from "../types/experimentTypes";
import { IUser } from "../types/UserTypes";
import { useAppContext } from "../components/context/AppContext";

import IExperimentSession from "../types/ExperimentSessionTypes";
import axios from "axios";
import { stringify } from "querystring";
import { toEditorSettings } from "typescript";
import SessionThumbnail from "../components/SessionThumbnail";
import { fetchWithRetry } from "../helper/axiosHelper";
import { isEditorPopulated, isOwnerPopulated } from "../helper/helper";
export interface IExperimentResponse {
  experiment: IExperiment;
  allSessions: IExperimentSession[];
}
const ShowExperimentPage: React.FC = () => {
  const [experiment, setExperiment] = useState<IExperiment | null>(null);
  const [allSessions, setAllSessions] = useState<IExperimentSession[] | null>(
    null
  );
  const [selectedSessionsForDownload, setSelectedSessionsForDownload] =
    useState<IExperimentSession[] | null>(null);
  const [expandedGroups, setExpandedGroups] = useState<{
    [key: string]: boolean;
  }>({});
  const [expandedDownloads, setExpandedDownloads] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);

  const navigate = useNavigate();
  const inviteInputRef = useRef<HTMLInputElement>(null);
  const {
    userToken,
    setAlert,
    setError,
    isUserLoggedIn,
    setFlash,
    setIsLoading,
  } = useAppContext();

  const [unplacedSessions, setUnplacedSessions] = useState<
    IExperimentSession[] | null
  >(null);
  const [organizedSessions, setOrganizedSessions] = useState<any>(null);

  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const site = searchParams.get("site");
  const date = searchParams.get("date");

  const fetchUserData = async () => {
    try {
      if (!isUserLoggedIn || !userToken) {
        return;
      }
      const result = await fetchWithRetry<IUser>(`/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        withCredentials: true, // Include cookies in the request
      });

      if (result.data) {
        setUser(result.data);
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setFlash({
        message: errorMessage,
        status: "failure",
      });
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [isUserLoggedIn, userToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchWithRetry(
          `/api/experiments/${id}?signedUrls=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (result.data.experiment) {
          setExperiment(result.data.experiment);
        }
        if (result.data.allSessions) {
          setAllSessions(result.data.allSessions);
          setUnplacedSessions(result.data.allSessions);
        }
      } catch (error: any) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setFlash({ status: "failure", message: errorMessage });
      }
    };

    fetchData();
  }, [site, date, userToken]);
  useEffect(() => {
    if (unplacedSessions) {
      // Step 1: Gather unique identifiers
      const uniqueSites = [
        ...new Set(unplacedSessions.map((session) => session.site)),
      ].sort();

      // Step 2: Create nested structures to hold the organized data
      const organizedSessions = uniqueSites.map((site) => {
        const sessionsBySite = unplacedSessions.filter(
          (session) => session.site === site
        );

        const uniqueSpecies = [
          ...new Set(sessionsBySite.map((session) => session.species)),
        ].sort();

        const sessionsBySpecies = uniqueSpecies.map((species) => {
          const sessionsByDateSpecies = sessionsBySite.filter(
            (session) => session.species === species
          );

          const uniqueDates = [
            ...new Set(sessionsByDateSpecies.map((session) => session.date)),
          ].sort();

          const sessionsByDate = uniqueDates.map((date) => {
            const sessions = sessionsByDateSpecies.filter(
              (session) => session.date === date
            );
            return { date, sessions };
          });

          return { species, dateGroups: sessionsByDate };
        });

        return { site, speciesGroups: sessionsBySpecies };
      });

      setOrganizedSessions(organizedSessions);
    }
  }, [unplacedSessions]);

  const handleAlertInvite = () => {
    const handleSiteSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (inviteInputRef.current) {
        if (inviteInputRef.current.value) {
          sendInviteData(inviteInputRef.current.value);
          setAlert(null);
        }
      }
    };

    const invitePicker = (
      <div>
        <form onSubmit={handleSiteSubmit}>
          <label htmlFor="site-input">Enter your collaborator's email.</label>
          <input
            id="site-input"
            className="form-control"
            ref={inviteInputRef}
          />
          <button className="btn btn-success m-2">Submit</button>
        </form>
      </div>
    );
    setAlert(invitePicker);
  };

  const sendInviteData = async (email: string) => {
    // Make the API request
    try {
      if (!isUserLoggedIn) {
        throw new Error("user is not logged in");
      }
      if (!email) {
        throw new Error("user is not logged in");
      }
      if (!userToken) {
        throw new Error("userToken is missing");
      }
      const data = {
        experiment: experiment,
        email: email,
      };
      const response = await axios.post<{ experiment: IExperiment }>(
        `${process.env.REACT_APP_API_URL}/api/invite/`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );
      if (response.status === 200) {
        setFlash({
          message: "Successfully invited a collaborator",
          status: "success",
        });
      }
    } catch (error: any) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setError({ title: "Unable to send invite", message: errorMessage });
    }
  };

  function toggleExpand(groupKey: string) {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupKey]: !prev[groupKey],
    }));
  }
  function toggleExpandDownloads() {
    setExpandedDownloads((prev) => !prev);
  }

  const downloadPhotos = async (siteName) => {
    if (!id) {
      setFlash({ status: "failure", message: "Experiment not found" });
      return;
    }
    setIsLoading(true);
    let sessionsDownload = allSessions;
    if (siteName) {
      if (siteName === "Unknown Site")
        sessionsDownload = allSessions.filter((session) => session.site === "");
      else {
        sessionsDownload = allSessions.filter(
          (session) => session.site === siteName
        );
      }
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/experiments/${id}/download`,
        {
          sessionsDownload,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          responseType: "blob", // Expecting a blob response

          withCredentials: true, // Include cookies in the request
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "photos.zip"); // Set desired filename here
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      URL.revokeObjectURL(url); // Free up memory
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setFlash({ status: "failure", message: errorMessage });
    }
  };

  const handleDownloadPhotos = (name) => {
    downloadPhotos(name);
  };
  return (
    <Layout>
      <Link to={`/experiments/my-experiments`} className="m-2 col-auto">
        &larr; Back to My Experiments
      </Link>
      <h1>View Experiment</h1>
      {experiment ? (
        <div>
          {isEditorPopulated(user, experiment) && (
            <Link
              to={`/experiments/${experiment._id}/sessions/add`}
              className="btn btn-success m-2"
            >
              Upload a Photo
            </Link>
          )}
          {isOwnerPopulated(user, experiment) && (
            <button className="btn btn-success m-2" onClick={handleAlertInvite}>
              Invite a collaborator
            </button>
          )}

          <div className="card">
            <div className="card-body">
              <p className="card-title fw-bold">
                Experiment Title:{" "}
                <span className="fw-normal">{experiment.title}</span>
              </p>

              <ul className="list-unstyled">
                {organizedSessions &&
                  organizedSessions.map((siteGroup, i) => (
                    <li key={i} className="">
                      <p className="mb-0">Site: {siteGroup.site}</p>
                      <ul className="list-unstyled ms-3">
                        {siteGroup.speciesGroups.map((speciesGroup, j) => (
                          <li key={j} className="">
                            <p className="mb-0">
                              Species: {speciesGroup.species}
                            </p>
                            <ul className="list-unstyled ms-3">
                              {speciesGroup.dateGroups.map((dateGroup, m) => (
                                <li key={m} className="">
                                  <button
                                    className={classes.resetButton}
                                    onClick={() => {
                                      toggleExpand(`${i}-${j}-${m}`);
                                    }}
                                  >
                                    <span
                                      className={`${classes.caret} ${
                                        expandedGroups[`${i}-${j}-${m}`]
                                          ? `${classes.expanded}`
                                          : ""
                                      }`}
                                    ></span>
                                    <span>Date: {dateGroup.date}</span>
                                  </button>
                                  {expandedGroups?.[`${i}-${j}-${m}`] ===
                                    true && (
                                    <ul
                                      className={`list-unstyled ms-3 ${classes.thumbnailList}`}
                                    >
                                      {dateGroup.sessions.map((session, n) => (
                                        <li key={n} className="">
                                          <SessionThumbnail
                                            session={session}
                                            experiment={experiment}
                                          ></SessionThumbnail>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="card-body">
              <p className="fw-bold">Additional Experiment Information:</p>
              {isUserLoggedIn && (
                <p className="card-text fw-bold">
                  Owner:{" "}
                  <span className="fw-normal">
                    {(experiment.owner as any)?.email}
                  </span>
                </p>
              )}
              {isUserLoggedIn && (
                <p className="card-text fw-bold">
                  Editors:{" "}
                  <span className="fw-normal">
                    {Array.isArray(experiment.editors as any)
                      ? experiment.editors.map((editor: any, index) => {
                          const lastElement =
                            experiment.editors.length - 1 === index;
                          return (
                            <span>
                              {editor.email}
                              {!lastElement ? "," : ""}{" "}
                            </span>
                          );
                        })
                      : ""}
                  </span>
                </p>
              )}
              <p className="card-text fw-bold">
                Description:{" "}
                <span className="fw-normal">{experiment.description}</span>
              </p>
              <p className="card-text fw-bold">
                Location:{" "}
                <span className="fw-normal">{experiment.location}</span>
              </p>
              <p className="card-text fw-bold">
                Guidelines:{" "}
                <span className="fw-normal">{experiment.guidelines}</span>
              </p>

              {isEditorPopulated(user, experiment) && (
                <div>
                  <p className="card-text">
                    {experiment.sampleSets?.length === 0 &&
                      "There are no templates yet. You can create a template by adding data."}
                  </p>
                  {experiment.sampleSets?.length > 0 && (
                    <span className="fw-bold">Templates:</span>
                  )}

                  {experiment.sampleSets?.map((val, index) => (
                    <div key={index}>
                      <Link
                        to={`/experiments/${experiment._id}/sample-sets/${val._id}`}
                      >
                        {val.sampleSetName}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
              <br />
              <div>
                {isEditorPopulated(user, experiment) &&
                  organizedSessions?.length > 0 && (
                    <div>
                      <p className="card-text fw-bold">
                        Photo Download Options:{" "}
                      </p>
                      <button
                        onClick={(e) => handleDownloadPhotos(null)}
                        className={`${classes.resetButton} m-1`}
                      >
                        Download All Photos
                      </button>
                      <button
                        className={`${classes.resetButton} m-1`}
                        onClick={toggleExpandDownloads}
                      >
                        <span
                          className={`${classes.caret} ${
                            expandedDownloads ? `${classes.expanded}` : ""
                          }`}
                        ></span>
                        <span>Download Photos by Site:</span>
                      </button>

                      {expandedDownloads && organizedSessions && (
                        <div>
                          {organizedSessions.map((siteGroup, i) => (
                            <div key={i} className="ms-4">
                              <Link
                                to="#"
                                onClick={(e) =>
                                  handleDownloadPhotos(
                                    siteGroup.site || "Unknown Site"
                                  )
                                }
                              >
                                Download photos from{" "}
                                {siteGroup.site || "Unknown Site"}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                <br />
                {isEditorPopulated(user, experiment) && (
                  <Link
                    to={`/experiments/${experiment._id}/edit`}
                    className="btn-primary btn m-2"
                  >
                    Edit This Experiment
                  </Link>
                )}
                {isEditorPopulated(user, experiment) && (
                  <Link
                    to={`/experiments/${experiment._id}/sample-sets/add`}
                    className="btn btn-primary m-2"
                  >
                    Add New Template
                  </Link>
                )}
              </div>
            </div>
          </div>
          <br />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Layout>
  );
};

export default ShowExperimentPage;
