import React from "react";
import classes from "./FlashModal.module.css";
import { useRef } from "react";
import { useAppContext } from "./context/AppContext";

interface FlashModalProps {
  onClose: () => void;
  status: "success" | "failure";
  message: string;
}

const FlashModal: React.FC<FlashModalProps> = ({
  onClose,
  status,
  message,
}) => {
  const { flashRef } = useAppContext();
  const modalClass = status === "success" ? classes.success : classes.failure;

  return (
    <div className={`${classes.modalWrapper} ${modalClass}`} ref={flashRef}>
      <div className={classes.modalPopup}>
        <p>{message}</p>
      </div>
      <button onClick={onClose}>&times;</button>
    </div>
  );
};

export default FlashModal;
