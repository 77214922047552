import MainNavigation from "./MainNavigation";
import classes from "./Layout.module.css";
import React, { FC, ReactNode, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "../../firebase";

import ErrorModal from "../ErrorModal";
import { useAppContext } from "../context/AppContext";
import MultiPurposeModal from "../MultiPurposeModal";
import FlashModal from "../FlashModal";
import { access } from "fs";
interface LayoutProps {
  children: ReactNode;
}
const auth = getAuth(app);

const Layout: FC<LayoutProps> = (props) => {
  const {
    error,
    setError,
    isUserLoggedIn,
    setIsUserLoggedIn,
    alert,
    setAlert,
    setUserToken,
    flash,
    setFlash,
  } = useAppContext();

  const closeErrorModal = () => {
    setError(null);
  };
  const closeAlertModal = () => {
    setAlert(null);
  };
  const closeFlashModal = () => {
    setFlash(null);
  };

  useEffect(() => {
    const auth = getAuth();

    const handleTokenRefresh = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const accessToken = await user.getIdToken(true); // Force-refresh the token
          setUserToken(accessToken);
        } catch (error: any) {
          console.error("Error refreshing token:", error);
        }
      }
    };

    // Refresh token every 50 minutes to ensure it's always valid
    // Firebase tokens expire in 60 minutes, but we're refreshing a bit earlier to be safe.
    const tokenRefreshInterval = setInterval(
      handleTokenRefresh,
      50 * 60 * 1000
    );

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const accessToken = await user.getIdToken();
          setUserToken(accessToken);
          setIsUserLoggedIn(true);
        } catch (error: any) {
          let errorMessage = error.message;
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            errorMessage = error.response.data.error;
          }
          setFlash({ status: "failure", message: errorMessage });
        }
      } else {
        setUserToken(null);
        setIsUserLoggedIn(false);
      }
    });

    // Cleanup on component unmount
    return () => {
      unsubscribe();
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  return (
    <div>
      {error && <ErrorModal messageObject={error} onClose={closeErrorModal} />}
      {alert && (
        <MultiPurposeModal onClose={closeAlertModal}>{alert}</MultiPurposeModal>
      )}

      <MainNavigation />
      <main className={classes.main}>
        {flash && (
          <FlashModal
            onClose={closeFlashModal}
            status={flash.status}
            message={flash.message}
          ></FlashModal>
        )}
        {props.children}
      </main>
    </div>
  );
};

export default Layout;
