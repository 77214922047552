import Layout from "../components/layout/Layout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import classes from "./ShowMyExperimentsPage.module.css";
import axios from "axios";
import { useAppContext } from "../components/context/AppContext";
import { IExperiment } from "../types/experimentTypes";
import NotSignedInCard from "../components/NotSignedInCard";
import { fetchWithRetry } from "../helper/axiosHelper";
const ShowMyExperimentsPage: React.FC = () => {
  const [experimentList, setExperimentList] = useState<IExperiment[]>(null);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const { isUserLoggedIn, userToken, setFlash, flash } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isUserLoggedIn) {
          return;
        }
        setLocalLoading(true);
        const result = await fetchWithRetry<IExperiment[]>(
          `/api/experiments/my-experiments`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
            withCredentials: true, // Include cookies in the request
          }
        );
        setLocalLoading(false);
        setExperimentList(result.data);
      } catch (error) {
        setLocalLoading(false);
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setFlash({
          message: errorMessage,
          status: "failure",
        });
        console.error("this is the error", errorMessage);
      }
    };
    fetchData();
  }, [isUserLoggedIn, userToken]);

  return (
    <Layout>
      <div className={`container`}>
        <h1>My Experiments</h1>

        {!isUserLoggedIn && <NotSignedInCard />}
        {isUserLoggedIn && (
          <div>
            <Link to="/experiments/create" className="btn btn-primary m-2">
              Create Experiment
            </Link>
            {localLoading && <p>Loading your Experiment information...</p>}
            <div className={classes.cardContainer}>
              {experimentList &&
                experimentList.length > 0 &&
                experimentList.map((experiment) => (
                  <div key={experiment._id} className={`${classes.card} card`}>
                    <div className="card-body">
                      <p className="card-title">Title: {experiment.title}</p>
                      <p className="card-text">
                        Description: {experiment.description}
                      </p>
                      <div className={classes.displayFlex}>
                        <Link
                          to={`/experiments/${experiment._id}`}
                          className="btn btn-primary mb-2"
                        >
                          View Experiment
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              {experimentList && experimentList.length === 0 && (
                <p>There are no experiments to show...</p>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ShowMyExperimentsPage;
