import React from "react";
import classes from "./ErrorModal.module.css";
interface ErrorState {
  message: string;
  title: string;
}
interface ErrorModalProps {
  messageObject: ErrorState;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ messageObject, onClose }) => {
  return (
    <div className={classes.errorModal}>
      <div className={classes.errorModalContent}>
        <h2 className={classes.errorHeader}>
          {messageObject?.title || "An error has occured"}
        </h2>
        <p>{messageObject?.message}</p>
        <button className="btn btn-danger" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;
