import React, { ReactNode, Dispatch, SetStateAction, RefObject } from "react";
import { useAppContext } from "./context/AppContext";
interface ErrorType {
  message: string;
  title: string;
}

const LoadingAlert: React.FC = () => {
  return (
    <div>
      <span>Processing your request...</span>
    </div>
  );
};

export default LoadingAlert;
