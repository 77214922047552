import Layout from "../components/layout/Layout";
import classes from "./CreateSessionPage.module.css";
import {
  useParams,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState, FormEvent, useRef } from "react";
import { IExperiment, ISampleSet } from "../types/experimentTypes";
import IExperimentSession from "../types/ExperimentSessionTypes";
import axios from "axios";
import { useAppContext } from "../components/context/AppContext";
import Picker from "../components/Picker";
import {
  experimentSessionShape,
  partialExperimentSessionShape,
  addFieldShapeForSession,
} from "../helper/helper";
import { setConstantValue } from "typescript";
import { fetchWithRetry } from "../helper/axiosHelper";

// this page will also edit sample sets
// fix me-eventually I should remove the sessionIndex

export interface IExperimentResponse {
  experiment: IExperiment;
  experimentSession: IExperimentSession;
}

export interface ISessionResponse {
  experiment: IExperiment;
  experimentSession: IExperimentSession;
  signedUrlList: [];
}

interface ISessionOnlyResponse {
  session: IExperimentSession;
}
interface ISampleSetResponse {
  sampleSet: ISampleSet;
}

const AddDataPage: React.FC = () => {
  const { setError } = useAppContext();
  const { setFlash, userToken, setAlert, setIsLoading } = useAppContext();

  const navigate = useNavigate();

  const [experiment, setExperiment] = useState<IExperiment | null>(null);
  const [selectedSampleSetIndex, setSelectedSampleSetIndex] =
    useState<string>("-1");
  const [currentSampleSet, setCurrentSampleSet] = useState<ISampleSet | null>(
    null
  );
  const [files, setFiles] = useState<FileList | null>(null);
  const [newDate, setNewDate] = useState<string | null>(null);
  const [newSite, setNewSite] = useState<string | null>(null);
  const [newSpecies, setNewSpecies] = useState<string | null>(null);
  const [signedUrlList, setSignedUrlList] = useState([]);
  const [deletedImageList, setDeletedImageList] = useState<boolean[] | null>(
    null
  );
  const [experimentSession, setExperimentSession] =
    useState<IExperimentSession | null>(experimentSessionShape);

  const params = useParams<{ id: string; sessionId: string }>();
  const [searchParams] = useSearchParams();
  const dateInputRef = useRef<HTMLInputElement>(null);
  const siteInputRef = useRef<HTMLInputElement>(null);
  const speciesInputRef = useRef<HTMLInputElement>(null);
  const samplesetNameInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef(null);
  const hiddenSubmitButtonRef = useRef(null);
  const [submitType, setSubmitType] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  const id = params.id;
  const sessionId = params.sessionId;
  const sessionIndex = searchParams.get("session-index");
  let filteredFields: string[] = [];
  // fix me-the chosen set will be selected by the user in the future.

  const fetchSampleSetData = async (sampleSetId) => {
    if (sampleSetId) {
      try {
        const result = await fetchWithRetry<ISampleSetResponse>(
          `/api/experiments/${id}/sample-sets/${sampleSetId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (result.data.sampleSet) {
          setCurrentSampleSet(result.data.sampleSet);
        }
      } catch (error: any) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setError({ title: "There was an error!", message: errorMessage });
      }
    } else {
      console.error("User token does not exist.");
    }
  };

  // Now, you can invoke the generic function with specific parameters:

  const showAlertDatePicker = () => {
    setAlert(
      Picker({
        label: "Date",
        inputRef: dateInputRef,
        experimentList: experiment.dateList,
        setNewItem: setNewDate,
        setError,
        setAlert,
      })
    );
  };

  const showAlertSitePicker = () => {
    setAlert(
      Picker({
        label: "Site",
        inputRef: siteInputRef,
        experimentList: experiment.siteList,
        setNewItem: setNewSite,
        setError,
        setAlert,
      })
    );
  };

  const showAlertSpeciesPicker = () => {
    setAlert(
      Picker({
        label: "Species",
        inputRef: speciesInputRef,
        experimentList: experiment.speciesList,
        setNewItem: setNewSpecies,
        setError,
        setAlert,
      })
    );
  };

  const showSampleSetNamePicker = () => {
    const handleSampleSetNameSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (samplesetNameInputRef.current) {
        if (samplesetNameInputRef.current.value) {
          const newSampleSetName = samplesetNameInputRef.current.value;

          handleSubmission(true, newSampleSetName);
          setAlert(null);
        }
      }
    };

    const sampleSetNamePicker = (
      <div>
        <form onSubmit={handleSampleSetNameSubmit}>
          <label htmlFor="sample-set-name-input">
            What would you like to call this template?{" "}
          </label>
          <input
            id="sample-set-name-input"
            className="form-control"
            ref={samplesetNameInputRef}
          />
          <button className="btn btn-success m-2">Submit</button>
        </form>
      </div>
    );
    setAlert(sampleSetNamePicker);
  };

  useEffect(() => {
    const fetchExperimentData = async () => {
      const result = await fetchWithRetry<IExperimentResponse>(
        `/api/experiments/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          withCredentials: true, // Include cookies in the request
        }
      );

      if (result.data.experiment) {
        setExperiment(result.data.experiment);
      }
    };
    const fetchSessionData = async () => {
      try {
        const result = await fetchWithRetry<ISessionResponse>(
          `/api/experiments/${id}/sessions/${sessionId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
            withCredentials: true, // Include cookies in the request
          }
        );

        if (result.data.experiment) {
          setExperiment(result.data.experiment);
        }
        if (result?.data?.experimentSession) {
          setExperimentSession(result.data.experimentSession);
          fetchSampleSetData(result.data.experimentSession.sampleSetId);
        }
        if (result.data?.signedUrlList) {
          setSignedUrlList(result.data.signedUrlList);
          setDeletedImageList(result.data.signedUrlList.map((e) => false));
        }
      } catch (error: any) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setFlash({ status: "success", message: errorMessage });
      }
    };

    if (sessionId && id) {
      fetchSessionData();
    } else if (id) {
      if (id) {
        fetchExperimentData();
      }
    }
  }, [id, sessionId]);

  const updateExperimentListField = async (listName, newValue) => {
    try {
      let newList = experiment[listName] || [];
      newList = [...newList, newValue];

      const result = await axios.put<IExperimentResponse>(
        `${process.env.REACT_APP_API_URL}/api/experiments/${id}`,
        { [listName]: newList },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );

      if (result.data.experiment) {
        setExperiment(result.data.experiment);
        setExperimentSession((prev) => ({
          ...prev,
          [listName.split("List")[0]]: newValue,
        }));
      }
    } catch (error: any) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setError({ title: "There was an error!", message: errorMessage });
    }
  };
  useEffect(() => {
    if (experiment && newSite && id) {
      updateExperimentListField("siteList", newSite);
    }
  }, [newSite]);

  useEffect(() => {
    if (experiment && newSpecies && id) {
      updateExperimentListField("speciesList", newSpecies);
    }
  }, [newSpecies]);

  useEffect(() => {
    if (experiment && newDate && id) {
      updateExperimentListField("dateList", newDate);
    }
  }, [newDate]);

  // whenever currentSampleSet updates, so does experimentSession (if no values are entered yet for that field):

  const resetSessionWithNewSampleSet = (newSampleSet: ISampleSet) => {
    if (newSampleSet) {
      // Create a deep copy of the experimentSession state
      const newExperimentSession = JSON.parse(
        JSON.stringify(experimentSession)
      );

      // Update the sampleSetId in the deep copy
      newExperimentSession.sampleSetId = newSampleSet._id;

      Object.keys(newSampleSet).forEach((item) => {
        if (newSampleSet[item].value && !newExperimentSession[item]) {
          newExperimentSession[item] = newSampleSet[item].value;
        } else if (item === "rows" || item === "columns") {
          newExperimentSession[item] = newSampleSet[item].map(
            (element, index2) => {
              if (
                element?.value.length > 0 &&
                !newExperimentSession[item]?.[index2]?.value
              ) {
                return element.value;
              } else {
                return newExperimentSession[item][index2];
              }
            }
          );
        } else if (item === "addFields") {
          newExperimentSession[item] = newSampleSet[item].map(
            (element, index2) => {
              if (
                element?.value.length > 0 &&
                !newExperimentSession[item]?.[index2]?.value &&
                !newExperimentSession[item]?.[index2]?.fieldTitle
              ) {
                return {
                  fieldTitle: element.fieldTitle,
                  value: element.value,
                };
              } else {
                return {
                  fieldTitle: newExperimentSession[item]?.[index2]?.value,
                  value: newExperimentSession[item]?.[index2]?.value,
                };
              }
            }
          );
        }
      });

      // Set the state once with the fully updated object
      setExperimentSession(newExperimentSession);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const target = event.target;
    let value = target.value;
    const name = target.name; // Type assertion
    if (event.target.value === "add-new-date") {
      showAlertDatePicker();
      value = ""; // Reset the value to an empty string

      return;
    } else if (event.target.value === "add-new-site") {
      showAlertSitePicker();
      value = ""; // Reset the value to an empty string

      return;
    } else if (event.target.value === "add-new-species") {
      showAlertSpeciesPicker();
      value = ""; // Reset the value to an empty string

      return;
    }
    if (name in experimentSessionShape) {
      setExperimentSession((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleArrayInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index2: number
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name; // Type assertion
    if (name === "addFields") {
      let newElement = value;
      let updatedContent = experimentSession[name];
      updatedContent[index2] = {
        ...experimentSession[name][index2],
        value: newElement,
      };
      setExperimentSession((prev) => ({
        ...prev,
        [name]: updatedContent,
      }));
    } else if (name === "addFields-fieldTitle") {
      let newElement = value;
      let updatedContent = experimentSession["addFields"];
      updatedContent[index2] = {
        ...experimentSession["addFields"][index2],
        fieldTitle: newElement,
      };
      setExperimentSession((prev) => ({
        ...prev,
        ["addFields"]: updatedContent,
      }));
    } else if (experimentSession && name in experimentSession) {
      let newElement = value;
      let updatedContent = experimentSession[name];
      updatedContent[index2] = newElement;
      // Set the state using spread operator to maintain existing values
      setExperimentSession((prev) => ({
        ...prev,
        [name]: updatedContent,
      }));
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(e.target.files);
      let reader = new FileReader();
      let file = e.target.files![0];

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result as string);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleSelectSampleSet = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let index = selectedSampleSetIndex;
    if (
      experiment &&
      experiment.sampleSets &&
      !(index === "-1") &&
      experiment.sampleSets[index]
    ) {
      const newSampleSet = experiment.sampleSets[index];
      setCurrentSampleSet(newSampleSet);
      resetSessionWithNewSampleSet(newSampleSet);
    } else if (index === "-1") {
      setCurrentSampleSet(null);
      if (experimentSession) {
        setExperimentSession((prev) => ({
          ...prev,
          sampleSetId: "",
        }));
      }
    }
  };

  const handleSubmission = async (
    isSaveTemplate: boolean,
    sampleSetName: string
  ) => {
    const formData = new FormData();

    if (isSaveTemplate && id) {
      setIsLoading(true);
      const newSampleSet = createSampleSetFromSessionData(
        experimentSession,
        sampleSetName
      );
      try {
        const response = await axios.post<ISampleSet>(
          `${process.env.REACT_APP_API_URL}/api/experiments/${id}/sample-sets`,
          newSampleSet,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },

            withCredentials: true, // Include cookies in the request
          }
        );

        if (response.status === 201) {
          // fix me-set up flash messages.
          setFlash({
            message: "Your template has been created",
            status: "success",
          });
        }
      } catch (error: any) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setError({ title: "There was an error!", message: errorMessage });
      }
    }
    if (files) {
      Array.from(files).forEach((file) => {
        formData.append("photos", file);
      });
    }
    if (experimentSession) {
      for (const key in experimentSession) {
        if (experimentSession.hasOwnProperty(key)) {
          const value =
            experimentSession[key as keyof typeof experimentSession];
          if (typeof value === "object") {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value as string); // assuming all other types can be coerced to string directly
          }
        }
      }
      // add deleteArray:
      if (deletedImageList) {
        formData.append("deletedImageList", JSON.stringify(deletedImageList));
      }
    }

    // Make the API request to edit:
    if (id && sessionId && experimentSession) {
      setIsLoading(true);
      try {
        const response = await axios.put<ISessionOnlyResponse>(
          `${process.env.REACT_APP_API_URL}/api/experiments/${id}/sessions/${sessionId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },

            withCredentials: true, // Include cookies in the request
          }
        );
        if (response.status === 200) {
          setIsLoading(false);

          navigate(`/experiments/${id}/sessions/${response.data.session._id}`);
        }
      } catch (error: any) {
        setIsLoading(false);
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }
        console.error("this is the error", error);

        setError({ title: "There was an error!", message: errorMessage });
      }
    } else if (id && experimentSession) {
      try {
        setIsLoading(true);
        const response = await axios.post<ISessionOnlyResponse>(
          `${process.env.REACT_APP_API_URL}/api/experiments/${id}/sessions`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },

            withCredentials: true, // Include cookies in the request
          }
        );
        if (response.status === 201) {
          setIsLoading(false);

          navigate(`/experiments/${id}/sessions/${response.data.session._id}`);
        }
      } catch (error: any) {
        setIsLoading(false);
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }
        console.error("error", error);
        setError({ title: "There was an error!", message: errorMessage });
      }
    }
  };

  const handleDeleteSession = async () => {
    setAlert(null);
    if (id && sessionId) {
      setIsLoading(true);
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/experiments/${id}/sessions/${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },

            withCredentials: true, // Include cookies in the request
          }
        );
        // 204 meaning content is deleted:
        if (response.status === 204) {
          setIsLoading(false);
          setFlash({ status: "success", message: "Data successfully deleted" });
          navigate(`/experiments/${id}`);
        }
      } catch (error: any) {
        setIsLoading(false);
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setError({ title: "There was an error!", message: errorMessage });
      }
    }
  };

  const setDeleteAlert = () => {
    const alertJSX = (
      <div>
        <p>Are you sure you want to delete this data?</p>
        <button
          className="btn btn-danger btn-sm m-2"
          onClick={handleDeleteSession}
        >
          Yes, I am sure.
        </button>
      </div>
    );

    setAlert(alertJSX);
  };

  const handleRowColAddDelete = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const buttonId = event.currentTarget.id;
    if (buttonId === "columns-add-button") {
      setExperimentSession((prevSession) => ({
        ...prevSession,
        columns: [...prevSession.columns, ""], // Update only the first column
      }));
    } else if (buttonId === "columns-delete-button") {
      setExperimentSession((prevSession) => ({
        ...prevSession,
        columns: [...prevSession.columns.slice(0, -1)], // Update only the first column
      }));
    } else if (buttonId === "rows-delete-button") {
      setExperimentSession((prevSession) => ({
        ...prevSession,
        rows: [...prevSession.rows.slice(0, -1)], // Update only the first column
      }));
    } else if (buttonId === "rows-add-button") {
      setExperimentSession((prevSession) => ({
        ...prevSession,
        rows: [...prevSession.rows, ""], // Update only the first column
      }));
    } else if (buttonId === "addFields-add-button") {
      setExperimentSession((prevSession) => ({
        ...prevSession,
        addFields: [...prevSession.addFields, addFieldShapeForSession], // Update only the first column
      }));
    } else if (buttonId === "addFields-delete-button") {
      setExperimentSession((prevSession) => ({
        ...prevSession,
        addFields: [...prevSession.addFields.slice(0, -1)], // Update only the first column
      }));
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitValue = (event.nativeEvent as any).submitter.value;
    if (submitValue === "template") {
      showSampleSetNamePicker();
      return;
    } else {
      handleSubmission(false, "");
    }
  };

  // This function takes the experimentSession and adds that information to a new sampleSet (eg template):

  const createSampleSetFromSessionData = (
    experimentSession,
    newSampleSetName: string
  ) => {
    const newSampleSet: ISampleSet = {
      sampleSetName: newSampleSetName,
      name: {
        included: true,
        required: false,
        value: experimentSession.name,
        locked: false,
      },
      colonies: {
        included: true,
        required: false,
        value: experimentSession.colonies,
        locked: false,
      },
      site: {
        included: true,
        required: false,
        value: experimentSession.site,
        locked: false,
      },
      date: {
        included: true,
        required: false,
        locked: false,
        value: experimentSession.date,
      },
      species: {
        included: true,
        required: false,
        value: experimentSession.species,
        locked: false,
      },
      notes: {
        included: true,
        required: false,
        value: experimentSession.notes,
        locked: false,
      },

      columns: experimentSession.columns.map((val) => ({
        included: true,
        required: false,
        value: val,
        locked: false,
      })),
      rows: experimentSession.rows.map((val) => ({
        included: true,
        required: false,
        value: val,
        locked: false,
      })),
      addFields: experimentSession.addFields.map((val) => ({
        included: true,
        required: false,
        value: val.value,
        locked: false,
        fieldTitle: val.fieldTitle,
      })),
    };
    return newSampleSet;
  };
  const handleDeleteImage = (e, index) => {
    if (deletedImageList) {
      setDeletedImageList((prev) => {
        // Create a shallow copy of the previous array
        let newArray = [...prev];
        if (prev[index] === true) {
          newArray[index] = false;
        } else if (prev[index] === false) {
          newArray[index] = true;
        }
        return newArray;
      });
    } else {
      console.log("deletedImageList does not exist.");
    }
  };

  return (
    <Layout>
      {sessionId ? (
        <h1>Edit Photo Data </h1>
      ) : (
        <h2>Upload a Photo {experiment && `to ${experiment.title}`}</h2>
      )}
      <div className="row justify-content-between">
        {experiment && (
          <Link to={`/experiments/${experiment._id}`} className="m-2 col-auto">
            &larr; Back to {experiment.title}
          </Link>
        )}
        {experiment && sessionId && (
          <button
            onClick={setDeleteAlert}
            className="btn btn-sm btn-danger m-2 col-auto"
          >
            Delete this Data
          </button>
        )}
      </div>

      {experiment && (
        <div>
          {experiment.sampleSets?.length > 0 ? (
            <form
              onSubmit={handleSelectSampleSet}
              ref={formRef}
              className="card"
            >
              <div className="card-body">
                <h5>Try picking a template to speed up data entry!</h5>
                <p>
                  Changing the template may restrict the fields that are
                  available to fill out.
                </p>
                <select
                  value={selectedSampleSetIndex}
                  className={`${classes.templatePicker} form-control`}
                  onChange={(e) => setSelectedSampleSetIndex(e.target.value)}
                >
                  <option value="-1"></option>
                  {experiment.sampleSets.map((key, index) => (
                    <option value={index}>
                      {key.sampleSetName
                        ? key.sampleSetName
                        : `Sample Set ${index + 1}`}
                    </option>
                  ))}
                </select>
                <button className="btn btn-primary m-2"> select</button>
              </div>
            </form>
          ) : (
            <p>
              There are no templates for this experiment yet. Once you fill out
              this form, if you plan on entering similar data, then we recommend
              clicking "Submit and save this template."
            </p>
          )}
        </div>
      )}
      {experiment && (
        <form onSubmit={(e) => handleFormSubmit(e)} className="my-4">
          <div className="input-group mb-3">
            <input
              type="file"
              className="form-control"
              id="photos"
              name="photos"
              onChange={handleFileChange}
            />
          </div>
          <div className="w-50 my-3">
            <img src={imagePreviewUrl} className={`img-fluid`} />
          </div>

          {experimentSession &&
            Object.keys(partialExperimentSessionShape).map(
              (key, index) =>
                !["addFields", "columns", "rows", "sampleSetName"].includes(
                  key
                ) && (
                  <div key={key} className="row mb-3">
                    <label htmlFor={key} className="col-sm-2 col-form-label">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </label>
                    <div className="col-sm-10">
                      {!(
                        key === "site" ||
                        key === "date" ||
                        key === "species"
                      ) && (
                        <input
                          id={key}
                          name={key}
                          type={key === "date" ? "date" : "text"}
                          onChange={handleInputChange}
                          disabled={currentSampleSet?.[key]?.locked}
                          required={currentSampleSet?.[key]?.required}
                          className="form-control"
                          value={experimentSession[key]}
                        />
                      )}
                      {(key === "site" ||
                        key === "date" ||
                        key === "species") && (
                        <select
                          id={key}
                          name={key}
                          onChange={handleInputChange}
                          disabled={currentSampleSet?.[key]?.locked}
                          required={currentSampleSet?.[key]?.required}
                          className="form-control"
                          value={experimentSession[key]?.toString()}
                        >
                          <option value=""></option>

                          {key === "site" &&
                            experiment.siteList?.map((val, index) => (
                              <option key={index}> {val}</option>
                            ))}
                          {key === "date" &&
                            experiment.dateList?.map((val, index) => (
                              <option key={index} value={val}>
                                {val}
                              </option>
                            ))}
                          {key === "species" &&
                            experiment.speciesList?.map((val, index) => (
                              <option key={index} value={val}>
                                {val}
                              </option>
                            ))}
                          {!experiment.dateListLocked && key === "date" && (
                            <option value="add-new-date">Add a new date</option>
                          )}
                          {!experiment.siteListLocked && key === "site" && (
                            <option value="add-new-site">Add a new site</option>
                          )}
                          {!experiment.speciesListLocked &&
                            key === "species" && (
                              <option value="add-new-species">
                                Add a new species
                              </option>
                            )}
                        </select>
                      )}
                    </div>
                  </div>
                )
            )}
          {Object.keys(partialExperimentSessionShape).map((key, index) => (
            <div key={`${key}`}>
              {["addFields", "columns", "rows"].includes(key) && (
                <div>
                  <h5 key={key}>
                    {key === "addFields"
                      ? "Additional Fields"
                      : key === "columns"
                      ? "Columns"
                      : key === "rows"
                      ? "Rows"
                      : ""}
                  </h5>
                  {/* only exclude this key if currentSampleSet exists AND included is set to false (default is true) */}
                  {experimentSession[key].map(
                    (element, index2) =>
                      !(
                        currentSampleSet?.[key][index2]?.included === false
                      ) && (
                        <div key={`${key}-${index2}`} className="row mb-3">
                          {key !== "addFields" && (
                            <label
                              htmlFor={key}
                              className="col-sm-2 col-form-label"
                            >
                              {key === "addFields"
                                ? `${experimentSession[key][index2]?.fieldTitle}`
                                : key === "columns"
                                ? `Column ${index2 + 1}`
                                : `Row ${index2 + 1}`}
                            </label>
                          )}
                          {key === "addFields" && (
                            <div className="col-sm-2">
                              <input
                                id="addFields-FieldTitle"
                                name={`${key}-fieldTitle`}
                                type="text"
                                onChange={(e) =>
                                  handleArrayInputChange(e, index2)
                                }
                                disabled={
                                  currentSampleSet?.[key][index2]?.locked
                                }
                                required
                                className=" form-control"
                                value={
                                  experimentSession[key][index2]?.fieldTitle
                                }
                              />
                            </div>
                          )}

                          <div className="col-sm-10">
                            <input
                              id={key}
                              name={key}
                              type={key === "date" ? "date" : "text"}
                              onChange={(e) =>
                                handleArrayInputChange(e, index2)
                              }
                              disabled={currentSampleSet?.[key][index2]?.locked}
                              required={
                                currentSampleSet?.[key][index2]?.required
                              }
                              className="form-control"
                              value={
                                key === "addFields"
                                  ? experimentSession[key][index2]?.value
                                  : experimentSession[key][index2]
                              }
                            />
                          </div>
                        </div>
                      )
                  )}
                  <button
                    className="btn btn-info m-2"
                    type="button"
                    id={`${key}-add-button`}
                    onClick={handleRowColAddDelete}
                  >
                    add a {key === "columns" ? "column" : "row"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger m-2"
                    id={`${key}-delete-button`}
                    onClick={handleRowColAddDelete}
                  >
                    remove the last {key === "columns" ? "column" : "row"}
                  </button>
                </div>
              )}
            </div>
          ))}
          <div className={`col-10`}>
            {signedUrlList?.map((value, index) => (
              <div key={value} className="d-flex flex-row">
                <img
                  src={value}
                  alt="corals"
                  className="img-fluid img-thumbnail"
                ></img>
                <div className="form-check mb-1">
                  <label htmlFor={`${value}-${index}-included`}>
                    {" "}
                    Delete this image?
                  </label>
                  <input
                    type="checkbox"
                    id={`${value}-${index}-delete`}
                    name={value}
                    className={`form-check-input`}
                    checked={deletedImageList?.[index]}
                    onChange={(e) => handleDeleteImage(e, index)}
                  ></input>
                </div>
              </div>
            ))}
          </div>

          <br />
          <button
            type="submit"
            name="submitType"
            value="regular"
            className="btn btn-primary m-2"
            onClick={() => setSubmitType("submit")}
          >
            Submit
          </button>
          <button
            type="submit"
            className="btn btn-primary m-2"
            name="submitType"
            value="template"
            onClick={() => setSubmitType("template")}
          >
            Submit and save this template
          </button>
        </form>
      )}
    </Layout>
  );
};

export default AddDataPage;
