import Layout from "../components/layout/Layout";
import axios from "axios";
import classes from "./DashboardPage.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ShowMyExperimentsPage from "./ShowMyExperimentsPage";
import { useAppContext } from "../components/context/AppContext";
import { IUser, IInvitation } from "../types/UserTypes";
import NotSignedInCard from "../components/NotSignedInCard";
import { sendEmailVerification, getAuth } from "firebase/auth";
import { app } from "../firebase";
import { fetchWithRetry } from "../helper/axiosHelper";

const DashboardPage: React.FC = () => {
  const {
    userToken,
    isUserLoggedIn,
    setError,
    setAlert,
    setFlash,
    setIsLoading,
  } = useAppContext();
  const [user, setUser] = useState<IUser | null>(null);
  const [receivedInvitations, setReceivedInvitations] = useState(null);
  const [sentInvitations, setSentInvitations] = useState(null);
  const [expandedSentInvites, setExpandedSentInvites] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const auth = getAuth(app);

  const fetchUserData = async () => {
    try {
      if (!isUserLoggedIn) {
        return;
      }
      const result = await fetchWithRetry<IUser>(`/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        withCredentials: true, // Include cookies in the request
      });

      if (result.data) {
        setUser(result.data);
        if (result.data.pendingInvitations) {
          if (result.data.pendingInvitations.received) {
            setReceivedInvitations(result.data.pendingInvitations.received);
          }
          if (result.data.pendingInvitations.sent) {
            setSentInvitations(result.data.pendingInvitations.sent);
          }
        }
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setFlash({
        message: errorMessage,
        status: "failure",
      });
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [isUserLoggedIn, userToken]);

  const acceptInvitationHandler = async (index: number) => {
    try {
      const invitation = receivedInvitations[index];
      // Perform API call to accept the invitation using necessary data from `invitation`
      if (!invitation || !invitation._id) {
        setError({
          message:
            "Some fields were missing-this invitation could not be accepted",
          title: "error!",
        });
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/invite/accept`,
        {
          invitation,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );
      if (response.status === 200) {
        setFlash({
          message: "You have successfully accepted the invitation",
          status: "success",
        });
        fetchUserData();
        if (invitation.experimentId) {
          navigate(`/experiments/${invitation?.experimentId._id}`);
        }
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setFlash({
        message: errorMessage,
        status: "failure",
      });
    }
  };

  const rejectInvitationHandler = async (index: number) => {
    try {
      const invitation = receivedInvitations[index];
      // Perform API call to reject the invitation using necessary data from `invitation`
      if (!invitation || !invitation._id) {
        setError({
          message: "Something went wrong-this invitation could not be rejected",
          title: "error!",
        });
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/invite/reject`,
        {
          invitation: invitation,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );
      if (response.status === 200) {
        if (response.status === 200) {
          setFlash({
            message: "You have successfully rejected the invitation",
            status: "success",
          });
          fetchUserData();
        }
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setFlash({
        message: errorMessage,
        status: "failure",
      });
    }
  };

  const deleteAccountText =
    "Are you sure you want to delete your account? This action cannot be reversed.";

  const setDeleteAlert = ({ text, handleDeleteItem }) => {
    const alertJSX = (
      <div>
        <p>{text}</p>
        <button
          className="btn btn-danger btn-sm m-2"
          onClick={handleDeleteItem}
        >
          Yes, I am sure.
        </button>
      </div>
    );

    setAlert(alertJSX);
  };
  const handleDeleteAccount = async () => {
    // Make the API request
    try {
      if (!isUserLoggedIn) {
        throw new Error("user is not logged in");
      }
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/user`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );
      // 204 meaning content is deleted:
      if (response.status === 204) {
        await auth.signOut();

        setIsLoading(false);
        setFlash({
          status: "success",
          message: "Your account has been deleted",
        });
        navigate(`/sign-in`);
      }
      setAlert(null);
    } catch (error: any) {
      setIsLoading(false);
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setAlert(null);
      setFlash({ status: "failure", message: errorMessage });
    }
  };
  const authenticateEmail = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      try {
        await sendEmailVerification(auth.currentUser);
        setAlert(
          <p>
            An email verification link has been sent to your email address.
            Please check your inbox.
          </p>
        );
      } catch (error) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setFlash({
          message: errorMessage,
          status: "failure",
        });
      }
    }
  };
  function toggleExpandSentInvites() {
    setExpandedSentInvites((prev) => !prev);
  }

  return (
    <Layout>
      <h1>Dashboard</h1>
      {!isUserLoggedIn && <NotSignedInCard></NotSignedInCard>}
      {isUserLoggedIn && (
        <div>
          <Link to="/experiments/create" className="btn btn-primary m-2">
            Create Experiment
          </Link>
          <Link
            to="/experiments/my-experiments"
            className="btn btn-primary m-2"
          >
            My Experiments
          </Link>
          {receivedInvitations && receivedInvitations.length > 0 && (
            <div>
              {receivedInvitations?.map(
                (element: any, index) =>
                  element.status === "Pending" && (
                    <div className="card my-2" key={index}>
                      <div className="card-body">
                        <h2>You've been invited!</h2>

                        <p>
                          <span>
                            {element.senderId?.email} has invited you to edit{" "}
                          </span>
                          <span>
                            {" "}
                            <Link
                              to={`/experiments/${element?.experimentId?._id}`}
                            >
                              this experiment: {element?.experimentId?.title}
                            </Link>
                          </span>
                        </p>
                        <button
                          className="btn btn-success m-2"
                          onClick={() => acceptInvitationHandler(index)}
                        >
                          Accept this invitation
                        </button>
                        <button
                          className="btn btn-danger m-2"
                          onClick={() => rejectInvitationHandler(index)}
                        >
                          Reject this invitation
                        </button>
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
          <button
            className={`${classes.resetButton} m-1`}
            onClick={toggleExpandSentInvites}
          >
            <span
              className={`${classes.caret} ${
                expandedSentInvites ? `${classes.expanded}` : ""
              }`}
            ></span>
            <span>View pending invitations that I have sent</span>
          </button>
          {expandedSentInvites &&
            sentInvitations &&
            sentInvitations.length > 0 && (
              <div>
                {sentInvitations?.map(
                  (element: any, index) =>
                    element.status === "Pending" && (
                      <div className="card my-2" key={index}>
                        <div className="card-body">
                          <p>
                            <span>
                              {element.receiverId?.email} has not yet responded
                              to your invite to{" "}
                            </span>
                            <Link
                              to={`/experiments/${element?.experimentId?._id}`}
                            >
                              this experiment: {element?.experimentId?.title}
                            </Link>
                          </p>
                        </div>
                      </div>
                    )
                )}
              </div>
            )}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className=" col-sm-7">
                  <h4>Account Information:</h4>
                  <p>Email: {user?.email}</p>
                  {auth.currentUser &&
                    !auth.currentUser.emailVerified && ( // Display the button only if the email is not yet verified
                      <button
                        className="btn btn-info"
                        onClick={authenticateEmail}
                      >
                        Send me another authentication email
                      </button>
                    )}
                </div>
                <div className=" col-sm-5 d-flex align-items-start justify-content-end">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={(e) => {
                      setDeleteAlert({
                        text: deleteAccountText,
                        handleDeleteItem: handleDeleteAccount,
                      });
                    }}
                  >
                    Delete my account
                  </button>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </Layout>
  );
};

export default DashboardPage;
