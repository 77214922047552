import React from "react";
import classes from "./SessionThumbnail.module.css";
import { Link } from "react-router-dom";

const SessionThumbnail = ({ session, experiment }) => {
  return (
    <div className={`card ${classes.card}`}>
      <Link
        to={`/experiments/${experiment._id}/sessions/${session._id}`}
        className={`${classes.cardLink}`}
      >
        <img
          className="card-img-top"
          src={`${session?.photo?.[0]?.signedUrl}`}
          alt=""
        />

        <div className="card-body d-flex flex-column">
          <h6 className="text-center">Colonies</h6>
          <p> {session.colonies || "Unknown"}</p>
          <h6 className="text-center">Name</h6>
          <p>{session.name || "Unknown"}</p>
        </div>
      </Link>
    </div>
  );
};

export default SessionThumbnail;
