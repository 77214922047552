import Layout from "../components/layout/Layout";
import { useEffect, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { IExperiment } from "../types/experimentTypes";
import IExperimentSession from "../types/ExperimentSessionTypes";
import axios from "axios";
import { stringify } from "querystring";
export interface IExperimentResponse {
  experiment: IExperiment;
  filteredSessions: IExperimentSession[];
}
const SupportPage: React.FC = () => {
  return (
    <Layout>
      <Link to={`/experiments/my-experiments`} className="m-2 col-auto">
        &larr; View My Experiments
      </Link>
      <h1>FAQ</h1>
      <h2>What is iHotCoral?</h2>
      <p>
        iHotCoral is a website that facilitates organizing and uploading
        scientific photos relating to corals and coral experiments. iHotCoral
        allows users to upload images to the cloud, pair relevant data to the
        images, auto-populate recurring data, download all the images for an
        experiment, collaborate with scientists around the world, and more.
      </p>
      <h2>How do I get started?</h2>
      <p>
        First, create an account. Click on "sign in" and then "sign up". After
        you have created an account, an email will be sent to you. You must
        verify your email before adding experiments or data.
      </p>
      <p>
        To create an experiment, go to My Experiments and select Create
        Experiment.
      </p>
      <h2>Adding a photo and related data</h2>
      <p>
        After creating an experiment, you are ready to upload photos and add
        information. When filling out the fields, several fields will be
        drop-down menus. By adding items to the drop down menu, this will make
        future data entry easier.
      </p>
      <h2>What are Templates?</h2>
      <p>
        When you are uploading a photo and data, you will have the option to
        either upload the data directly or upload the data AND create a
        template. If you choose to create a template, then all the selections
        you have made will be saved and you can easily access them the next time
        you try to add data. The goal of templates is to make data entry easy.
      </p>

      <h2>How do I collaborate with others?</h2>
      <p>
        On your dashboard, you will receive notifications if anyone has invited
        you to collaborate on their experiment. You can view their experiment
        and decide whether or not to accept their collaboration request.
      </p>
      <p>
        To invite someone to collaborate with you, go to the "view experiment"
        page and then click the "invite a collaborator" button.
      </p>
      <p>
        When you invite someone to collaborate, if they do not have an account,
        they will receive an email invitation to sign up and join your
        experiment. If they do not see the email, remind them to check their
        spam folder. If you invite someone who already has an account, they can
        view that invitation on their dashboard.
      </p>
      <h2>Advanced Template notes:</h2>
      <p>
        By editing a template or by creating a new template without trying to
        add data, you can access more complex options for template creation.
      </p>
      <p>
        When creating a template , if you do not select "included", that field
        will show up when users are adding data, but NOT when viewing data. For
        example, you might not need the "notes" field, so unselect "included",
        and then when you are viewing that photo, it won't show a blank notes
        field.
      </p>
      <p>
        The "required" checkbox means that a field must be filled out during
        data entry in order to submit the data.
      </p>
      <p>
        The "default value" field will auto-fill the field with the information
        you provide.
      </p>
      <p>
        The "lock value to default value" checkbox means that the default value
        you provide cannot be changed during data entry when users have selected
        that template. So when users select your template, the information you
        provide in default value is guaranteed to be present.
      </p>
      <p>
        The columns and rows fields provide default values for the columns and
        rows that might show up in a photo that you upload. Columns go from left
        to right, and rows go from top to bottom.
      </p>
      <p>
        The "addFields" or "additional fields" option allows you to create
        additional fields that will show up during data entry.
      </p>
    </Layout>
  );
};

export default SupportPage;
