// AppContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import LoadingAlert from "../LoadingAlert";
interface ErrorState {
  message: string;
  title: string;
}
interface IFlash {
  status: "success" | "failure";
  message: string;
}

interface AppContextProps {
  error: ErrorState | null;
  setError: React.Dispatch<React.SetStateAction<ErrorState | null>>;
  isUserLoggedIn: boolean;
  setIsUserLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  alert: ReactNode | null;
  setAlert: React.Dispatch<React.SetStateAction<ReactNode | null>>;
  setUserToken: React.Dispatch<React.SetStateAction<string | null>>;
  userToken: string | null;
  setFlash: React.Dispatch<React.SetStateAction<IFlash | null>>;
  flash: IFlash | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  flashRef: React.RefObject<HTMLDivElement>;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: FunctionComponent<AppProviderProps> = ({
  children,
}) => {
  const [error, setError] = useState<{ message: string; title: string } | null>(
    null
  );
  const [alert, setAlert] = useState<ReactNode | null>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [flash, setFlash] = useState<IFlash | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const flashRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      setAlert(LoadingAlert);
    } else {
      setAlert(null);
    }
  }, [isLoading]);
  useEffect(() => {
    let timer: NodeJS.Timeout; // Declare a timer variable
    if (flash) {
      timer = setTimeout(() => {
        const flashElement = flashRef.current;
        if (flashElement) {
          flashElement.style.opacity = "0"; // start the fade out
          setTimeout(() => setFlash(null), 2000); // wait 2 seconds (transition duration), then remove
        }
      }, 30000); //30 seconds
    }
    return () => {
      if (timer) clearTimeout(timer); // Clean up the timer when the component is unmounted or if flash changes
    };
  }, [flash]);
  return (
    <AppContext.Provider
      value={{
        error,
        setError,
        isUserLoggedIn,
        setIsUserLoggedIn,
        alert,
        setAlert,
        setUserToken,
        userToken,
        setFlash,
        flash,
        isLoading,
        setIsLoading,
        flashRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
