import { IExperiment, ISampleSet } from "../types/experimentTypes";
import IExperimentSession from "../types/ExperimentSessionTypes";
import { IUser } from "../types/UserTypes";
export const fieldObject = {
  included: false,
  required: false,
  value: "",
  locked: false,
};
export const addFieldShape = {
  included: true,
  required: false,
  value: "",
  locked: false,
  fieldTitle: "",
};

export const addFieldShapeForSession = {
  fieldTitle: "",
  value: "",
};

export const sampleSetTemplateData: ISampleSet = {
  sampleSetName: "",
  colonies: {
    included: true,
    required: false,
    value: "",
    locked: false,
  },
  name: { included: true, required: false, value: "", locked: false },
  site: {
    included: true,
    required: false,
    value: "",
    locked: false,
  },
  date: {
    included: true,
    required: false,
    locked: false,
    value: "",
  },
  species: {
    included: true,
    required: false,
    value: "",
    locked: false,
  },
  notes: {
    included: true,
    required: false,
    value: "",
    locked: false,
  },

  columns: [],
  rows: [],
  addFields: [],
};
export const experimentSessionShape: IExperimentSession = {
  colonies: "",
  name: "",
  experimentId: "",
  site: "", // or you could use a more specific type if you know what data it will contain
  date: "",
  species: "",
  notes: "",
  photo: [
    {
      fileName: "",
      link: "",
    },
  ],
  columns: [""],
  rows: [""],
  sampleSetId: "",
  addFields: [],
};

type PartialExperimentSession = Partial<IExperimentSession>;

export const partialExperimentSessionShape: PartialExperimentSession = {
  colonies: "",
  name: "",
  site: "", // or you could use a more specific type if you know what data it will contain
  date: "",
  species: "",
  notes: "",
  columns: [""],
  rows: [""],
  addFields: [],
  // ... (add any other properties you want to include)
};

export const experimentTemplateData: IExperiment = {
  _id: "",
  title: "",
  description: "",
  guidelines: "",
  location: "",
  siteList: [],
  dateList: [],
  sampleSets: [
    {
      sampleSetName: "",
      name: fieldObject,
      site: fieldObject,
      date: {
        included: false,
        required: false,
        locked: false,
      },
      species: fieldObject,
      notes: fieldObject,
      columns: Array.from({ length: 5 }, () => ({
        included: true,
        required: true,
        value: "",
        fieldTitle: "",
        locked: false,
      })),
      rows: Array.from({ length: 5 }, () => ({
        included: true,
        required: true,
        value: "",
        fieldTitle: "",
        locked: false,
      })),
      addFields: [addFieldShape],
    },
  ],
};
export const isEditor = (user: IUser, experiment: IExperiment) => {
  return (
    user &&
    user._id &&
    experiment &&
    experiment.editors.includes(user._id.toString())
  );
};
export const isOwner = (user: IUser, experiment: IExperiment) => {
  return (
    user &&
    user._id &&
    experiment &&
    experiment.owner &&
    user._id.toString() === experiment.owner.toString()
  );
};
export const isEditorPopulated = (user: IUser, experiment: IExperiment) => {
  return (
    user &&
    user._id &&
    experiment &&
    (experiment.editors as any).some(
      (editor) => editor._id === user._id.toString()
    )
  );
};
export const isOwnerPopulated = (user: IUser, experiment: IExperiment) => {
  return (
    user &&
    user._id &&
    experiment &&
    experiment.owner &&
    user._id.toString() === (experiment.owner as any)?._id?.toString()
  );
};
