import React, { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { app } from "../firebase";
import styles from "./SignIn.module.css";
import { useAppContext } from "./context/AppContext"; // Adjust path accordingly
import { useNavigate } from "react-router-dom";

const auth = getAuth(app);

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { setUserToken, setFlash } = useAppContext();
  const navigate = useNavigate();

  const handleSignUp = async () => {
    // ... Inside your handleSignUp function

    try {
      setError(null);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Send email verification
      if (userCredential.user) {
        await sendEmailVerification(userCredential.user);
      }

      // Getting the token after successful sign-up
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        setUserToken(token); // Setting token in your context
        navigate(`/experiments/my-experiments`);
      }

      const firebaseUid = userCredential.user.uid;

      // Call your API to create a new user document in MongoDB
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/register`,
        {
          // Adjust the endpoint path
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ firebaseUid, email, firstName, lastName }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to register user in the database");
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setFlash({ status: "failure", message: errorMessage });
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Sign Up</h2>

      <div className={styles.field}>
        <label className={styles.label}>
          First Name:
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className={styles.input}
          />
        </label>
      </div>

      <div className={styles.field}>
        <label className={styles.label}>
          Last Name:
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className={styles.input}
          />
        </label>
      </div>

      <div className={styles.field}>
        <label className={styles.label}>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
        </label>
      </div>

      <div className={styles.field}>
        <label className={styles.label}>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />
        </label>
      </div>

      {error && <p className={styles.error}>{error}</p>}
      <button onClick={handleSignUp} className={styles.button}>
        Sign Up
      </button>
    </div>
  );
}

export default SignUp;
