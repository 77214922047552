import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// fix me-todo: move ids and apis to .env file

const firebaseConfig = {
  apiKey: "AIzaSyCdOihmPXD4vjKD4WTs_U4dgDpjQpWbbzc",
  authDomain: "ihotcoral.firebaseapp.com",
  projectId: "ihotcoral",
  storageBucket: "ihotcoral.appspot.com",
  messagingSenderId: "653522823944",
  appId: "1:653522823944:web:3a9fe6ef930404a2385abd",
  measurementId: "G-CDHK46W9T1",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
auth.useDeviceLanguage(); // Optionally set the auth instance to use the browser's language.
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Auth state persistence has been set to 'LOCAL'.");
  })
  .catch((error) => {
    console.error("Error setting auth state persistence: ", error);
  });

export const analytics = getAnalytics(app);
