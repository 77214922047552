import Layout from "../components/layout/Layout";
import React, { FormEvent, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import { ISampleSet, IExperiment } from "../types/experimentTypes";
import classes from "./EditExperimentPage.module.css";
import { useAppContext } from "../components/context/AppContext";
import { experimentTemplateData as emptyExperiment } from "../helper/helper";
import Picker from "../components/Picker";
import { fetchWithRetry } from "../helper/axiosHelper";
// fix me- I set sessionfields[0]

interface IResponseData {
  experiment: IExperiment;
}

const EditExperimentPage: React.FC = () => {
  const {
    isUserLoggedIn,
    userToken,
    setError,
    setFlash,
    setAlert,
    setIsLoading,
  } = useAppContext();
  const [newDate, setNewDate] = useState<string | null>(null);
  const [newSite, setNewSite] = useState<string | null>(null);
  const [newSpecies, setNewSpecies] = useState<string | null>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const siteInputRef = useRef<HTMLInputElement>(null);
  const speciesInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const [experiment, setExperiment] = useState<IExperiment>(emptyExperiment);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isUserLoggedIn) {
          return;
        }
        const result = await fetchWithRetry<IResponseData>(
          `/api/experiments/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
            withCredentials: true, // Include cookies in the request
          }
        );

        setExperiment(result.data.experiment);
      } catch (error: any) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }
        setFlash({ status: "failure", message: errorMessage });
      }
    };

    fetchData();
  }, [userToken]);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target;
    let value;
    if (target instanceof HTMLInputElement && target.type === "checkbox") {
      value = target.checked;
    } else {
      value = target.value;
    }
    const name = target.name;

    if (
      experiment &&
      experiment.sampleSets?.[0]?.hasOwnProperty(name) &&
      target.type === "checkbox"
    ) {
      setExperiment({
        ...experiment,
        sampleSets: {
          ...experiment.sampleSets,
          [name]: value,
        },
      });
    } else if (experiment) {
      setExperiment({
        ...experiment,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Make the API request
    try {
      if (!isUserLoggedIn) {
        throw new Error("user is not logged in");
      }
      setIsLoading(true);
      const response = await axios.put<{ experiment: IExperiment }>(
        `${process.env.REACT_APP_API_URL}/api/experiments/${id}`,
        experiment,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );
      if (response.status === 200 && response.data.experiment) {
        setIsLoading(false);
        setFlash({
          status: "success",
          message: "Successfully edited the experiment",
        });
        navigate(`/experiments/${response.data.experiment._id}`);
      }
    } catch (error: any) {
      setIsLoading(false);
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setFlash({ status: "failure", message: errorMessage });
    }
  };

  const handleDeleteExperiment = async () => {
    // Make the API request
    try {
      if (!isUserLoggedIn) {
        throw new Error("user is not logged in");
      }
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/experiments/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );
      // 204 meaning content is deleted:
      if (response.status === 204) {
        setIsLoading(false);
        setFlash({
          status: "success",
          message:
            "The experiment and all of its data has been successfully deleted",
        });
        navigate(`/dashboard`);
      }
      setAlert(null);
    } catch (error: any) {
      setIsLoading(false);
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setAlert(null);
      setFlash({ status: "failure", message: errorMessage });
    }
  };

  const handleSiteChange = (index: number, newSite: string) => {
    const newSiteList = [...experiment?.siteList];
    newSiteList[index] = newSite;
    setExperiment((prev) => ({ ...prev, siteList: newSiteList }));
  };

  const addSite = (val) => {
    setExperiment((prev) => ({ ...prev, siteList: [...prev.siteList, val] }));
  };

  const removeSite = (index: number) => {
    const newSiteList = [...experiment.siteList];
    newSiteList.splice(index, 1);
    setExperiment((prev) => ({ ...prev, siteList: newSiteList }));
  };
  const removeEditor = (index: number) => {
    setAlert(null);
    const newEditorList = [...experiment.editors];
    newEditorList.splice(index, 1);
    setExperiment((prev) => ({ ...prev, editors: newEditorList }));
  };

  // const addDate = () => {
  //   setExperiment((prev) => ({ ...prev, dateList: [...prev.dateList, ""] }));
  // };
  const addDate = (val) => {
    setExperiment((prev) => ({ ...prev, dateList: [...prev.dateList, val] }));
  };
  const removeDate = (index: number) => {
    const newDateList = [...experiment.dateList];
    newDateList.splice(index, 1);
    setExperiment((prev) => ({ ...prev, dateList: newDateList }));
  };

  const updateDate = (index: number, newDate: string) => {
    const newList = [...experiment.dateList];
    newList[index] = newDate;
    setExperiment((prev) => ({ ...prev, dateList: newList }));
  };
  const addSpecies = (val) => {
    setExperiment((prev) => ({
      ...prev,
      speciesList: [...prev.speciesList, val],
    }));
  };
  const removeSpecies = (index: number) => {
    const newSpeciesList = [...experiment.speciesList];
    newSpeciesList.splice(index, 1);
    setExperiment((prev) => ({ ...prev, speciesList: newSpeciesList }));
  };
  const updateSpecies = (index: number, newSpecies: string) => {
    const newList = [...experiment.speciesList];
    newList[index] = newSpecies;
    setExperiment((prev) => ({ ...prev, speciesList: newList }));
  };
  const setDeleteAlert = ({ text, handleDeleteItem }) => {
    const alertJSX = (
      <div>
        <p>{text}</p>
        <button
          className="btn btn-danger btn-sm m-2"
          onClick={handleDeleteItem}
        >
          Yes, I am sure.
        </button>
      </div>
    );

    setAlert(alertJSX);
  };
  const deleteExperimentText =
    "Are you sure you want to delete this experiment? This will also delete all the data associated with this experiment.";
  const deleteCollaboratorText =
    "Are you sure you want to remove this collaborator from this experiment? This action will not be saved until you click `update experiment` below.";

  interface emailObject {
    _id: string;
    email: string;
  }
  interface ListHandlerProps {
    label: string;
    list: string[] | emailObject[];
    onInputChange?: (index: number, value: string) => void;
    onRemoveClick: (index: number) => void;
    onAddClick?: () => void;
    inputType?: string; // If you need different input types (like "date" for Date List)
    experiment: IExperiment;
  }

  const ListHandler: React.FC<ListHandlerProps> = ({
    label,
    list,
    onInputChange,
    onRemoveClick,
    onAddClick,
    inputType = "text",
    experiment,
  }) => {
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
      // After updates, refocus on the input that lost focus
      inputRefs.current.forEach((inputRef) => {
        if (inputRef && document.activeElement === inputRef) {
          inputRef.focus();
        }
      });
    }, [list]); // Assuming list changes when the input changes, adjust as needed

    return (
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label">{label}</label>
        <div className="col-sm-10">
          {list.map((item, index) => (
            <div
              key={index}
              className="row align-items-center justify-content-between"
            >
              <div className="col-8">
                <input
                  type={inputType}
                  className="form-control"
                  disabled
                  value={label === "Editor List" ? item.email : item}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) =>
                    onInputChange && onInputChange(index, e.target.value)
                  }
                />
              </div>
              {(experiment?.owner as any)?._id !== (item as any)?._id && (
                <div className="col-3 d-flex justify-content-end">
                  <button
                    className="btn btn-sm btn-danger m-2"
                    type="button"
                    onClick={() => onRemoveClick(index)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          ))}
          {label !== "Editor List" && (
            <button
              className="btn btn-success m-2"
              type="button"
              onClick={onAddClick ? onAddClick : null}
            >
              Add {label.split(" ")[0]}{" "}
              {/* Extracting the first word from the label for the Add button */}
            </button>
          )}
        </div>
      </div>
    );
  };

  const showAlertDatePicker = () => {
    setAlert(
      Picker({
        label: "Date",
        inputRef: dateInputRef,
        experimentList: experiment.dateList,
        setNewItem: addDate,
        setError,
        setAlert,
      })
    );
  };
  const showAlertSitePicker = () => {
    setAlert(
      Picker({
        label: "Site List",
        inputRef: siteInputRef,
        experimentList: experiment.siteList,
        setNewItem: addSite,
        setError,
        setAlert,
      })
    );
  };
  const showAlertSpeciesPicker = () => {
    setAlert(
      Picker({
        label: "Species List",
        inputRef: speciesInputRef,
        experimentList: experiment.speciesList,
        setNewItem: addSpecies,
        setError,
        setAlert,
      })
    );
  };

  return (
    <Layout>
      <h1>Edit Experiment</h1>

      {experiment && (
        <div>
          <div className="row justify-content-between align-items-center mb-4">
            <Link to={`/experiments/${experiment._id}`} className="col-auto">
              View Experiment
            </Link>
            <button
              className="btn btn-danger btn-sm m-2 col-auto"
              onClick={(e) => {
                setDeleteAlert({
                  text: deleteExperimentText,
                  handleDeleteItem: handleDeleteExperiment,
                });
              }}
            >
              Delete This Experiment
            </button>
          </div>

          <form onSubmit={handleSubmit} className={classes.form}>
            <div className="row mb-3">
              <label htmlFor="title" className="col-sm-2 col-form-label">
                Title
              </label>
              <div className="col-sm-10">
                <input
                  id="title"
                  name="title"
                  value={experiment?.title}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                ></input>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2" htmlFor="description">
                Description
              </label>
              <div className="col-sm-10">
                <input
                  id="description"
                  name="description"
                  value={experiment?.description}
                  onChange={handleInputChange}
                  className="form-control"
                ></input>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2" htmlFor="guidelines">
                Guidelines
              </label>
              <div className="col-sm-10">
                <textarea
                  id="guidelines"
                  name="guidelines"
                  value={experiment?.guidelines}
                  className="form-control"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2" htmlFor="location">
                Location
              </label>
              <div className="col-sm-10">
                <input
                  id="location"
                  name="location"
                  value={experiment?.location}
                  onChange={handleInputChange}
                  className="form-control"
                ></input>
              </div>
            </div>
            <ListHandler
              label="Site List"
              list={experiment?.siteList || []}
              onInputChange={handleSiteChange}
              onRemoveClick={removeSite}
              onAddClick={showAlertSitePicker}
              experiment={experiment}
            />
            <ListHandler
              label="Date List"
              list={experiment?.dateList || []}
              onInputChange={updateDate}
              onRemoveClick={removeDate}
              onAddClick={showAlertDatePicker}
              inputType="date"
              experiment={experiment}
            />
            <ListHandler
              label="Species List"
              list={experiment?.speciesList || []}
              onInputChange={updateSpecies}
              onRemoveClick={removeSpecies}
              onAddClick={showAlertSpeciesPicker}
              experiment={experiment}
            />
            <ListHandler
              label="Editor List"
              list={experiment?.editors || []}
              onRemoveClick={(index) => {
                setDeleteAlert({
                  text: deleteCollaboratorText,
                  handleDeleteItem: () => removeEditor(index),
                });
              }}
              experiment={experiment}
            />

            <button className="btn btn-primary m-2">Update Experiment</button>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default EditExperimentPage;
