import React from "react";
import classes from "./MultiPurposeModal.module.css";

interface MultiPurposeModalProps {
  children: React.ReactNode;
  onClose: () => void;
}

const MultiPurposeModal: React.FC<MultiPurposeModalProps> = ({
  children,
  onClose,
}) => {
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modalPopup}>
        {children}
        <button className="btn btn-info" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default MultiPurposeModal;
