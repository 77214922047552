import Layout from "../components/layout/Layout";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../components/context/AppContext";

const HomePage: React.FC = () => {
  const { isUserLoggedIn } = useAppContext();
  return (
    <Layout>
      <div>
        <h1>Welcome to iHotCoral!</h1>
        {!isUserLoggedIn && (
          <Link to="/sign-in" className="btn btn-primary m-2">
            Sign in
          </Link>
        )}
        {isUserLoggedIn && (
          <div>
            <Link to="/dashboard" className="btn btn-primary m-2">
              Dashboard
            </Link>
            <Link
              to="/experiments/my-experiments"
              className="btn btn-primary m-2"
            >
              My Experiments
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default HomePage;
