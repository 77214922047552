import Layout from "../components/layout/Layout";
import { useEffect, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { IExperiment } from "../types/experimentTypes";
import IExperimentSession from "../types/ExperimentSessionTypes";
import SignUp from "../components/SignUp";
import { useAppContext } from "../components/context/AppContext";

export interface IExperimentResponse {
  experiment: IExperiment;
  filteredSessions: IExperimentSession[];
}
const SupportPage: React.FC = () => {
  const { isUserLoggedIn } = useAppContext();
  return (
    <Layout>
      {isUserLoggedIn && (
        <div>
          <p>You are already logged in. </p>
          <Link to={`/experiments/my-experiments`} className="m-2 col-auto">
            View My Experiments
          </Link>
        </div>
      )}
      {!isUserLoggedIn && (
        <div>
          <Link to="/sign-in">&larr; Back to Sign In page</Link>
          <SignUp></SignUp>
        </div>
      )}
    </Layout>
  );
};

export default SupportPage;
