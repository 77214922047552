import React from "react";
import classes from "./NotSignedInCard.module.css";
import { Link } from "react-router-dom";

const NotSignedInCard = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className={`card ${classes.card}`}>
        <div className="card-body d-flex flex-column">
          <h5>It looks like you aren't signed in yet...</h5>
          <br />
          <Link to="/sign-in" className="btn btn-success m-2">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotSignedInCard;
