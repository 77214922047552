import Layout from "../components/layout/Layout";
import { useEffect, useState } from "react";
import {
  useParams,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { IExperiment, ISampleSet } from "../types/experimentTypes";
import IExperimentSession from "../types/ExperimentSessionTypes";
import { IUser } from "../types/UserTypes";
import classes from "./ShowSessionPage.module.css";
import axios from "axios";
import { useAppContext } from "../components/context/AppContext";
import { fetchWithRetry } from "../helper/axiosHelper";
import { isEditor, isOwner } from "../helper/helper";

export interface IExperimentResponse {
  experiment: IExperiment;
  experimentSession: IExperimentSession;
  signedUrlList: [];
}

const ShowSessionPage: React.FC = () => {
  const { userToken, setFlash, isUserLoggedIn } = useAppContext();
  const navigate = useNavigate();
  const [experiment, setExperiment] = useState<IExperiment | null>(null);
  const [sampleSet, setSampleSet] = useState<ISampleSet | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [experimentSession, setExperimentSession] =
    useState<IExperimentSession | null>(null);
  const [signedUrlList, setSignedUrlList] = useState([]);

  const params = useParams<{ id: string; sessionId: string }>();
  const [searchParams] = useSearchParams();

  const experimentId = params.id;
  const sessionId = params.sessionId;
  const sessionIndex = searchParams.get("session-index");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchWithRetry<IExperimentResponse>(
          `/api/experiments/${experimentId}/sessions/${sessionId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
            withCredentials: true, // Include cookies in the request
          }
        );
        if (result.data.experiment) {
          setExperiment(result.data.experiment);
        }
        if (result?.data?.experimentSession) {
          setExperimentSession(result.data.experimentSession);
        }
        if (result.data?.signedUrlList) {
          setSignedUrlList(result.data.signedUrlList);
        }
      } catch (error: any) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setFlash({ status: "failure", message: errorMessage });
      }
    };
    if (experimentId && sessionId) {
      fetchData();
    }
  }, [experimentId, sessionId]);

  useEffect(() => {
    if (experiment && experimentSession) {
      if (experimentSession.sampleSetId) {
        let sampleSet;
        if (experiment.sampleSets) {
          sampleSet = experiment.sampleSets.find(
            (val) => val._id === experimentSession.sampleSetId
          );
        }
        // Do something with sampleSet if needed
        setSampleSet(sampleSet);
      }
    }
  }, [experiment, experimentSession]); // Added experimentSession to dependency array

  const fetchUserData = async () => {
    try {
      if (!isUserLoggedIn || !userToken) {
        return;
      }
      const result = await fetchWithRetry<IUser>(`/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        withCredentials: true, // Include cookies in the request
      });

      if (result.data) {
        setUser(result.data);
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setFlash({
        message: errorMessage,
        status: "failure",
      });
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [isUserLoggedIn, userToken]);

  return (
    <Layout>
      <div className="container">
        {experiment && (
          <div className="row justify-content-between align-items-center mb-4">
            <div className="col-auto">
              <Link to={`/experiments/${experiment._id}`} className="m-2">
                &larr; Back to Experiment: {experiment.title}
              </Link>{" "}
            </div>
            <div className="col-auto">
              {isEditor(user, experiment) && (
                <Link
                  to={`/experiments/${experiment._id}/sessions/add`}
                  className="btn btn-success m-2"
                >
                  Add Photo
                </Link>
              )}
            </div>
          </div>
        )}

        <h1>Photo Information</h1>
        {experiment && experimentSession ? (
          <div>
            <div className="card">
              <div className="card-body">
                {!(sampleSet?.colonies.included === false) && (
                  <div className={`${classes.cardField} card-text row`}>
                    <span className="fw-bold col-6 col-sm-5 col-md-3">
                      Colonies:
                    </span>
                    <span className="col-auto">
                      {experimentSession.colonies}
                    </span>
                  </div>
                )}
                {!(sampleSet?.name.included === false) && (
                  <div className={`${classes.cardField} card-text row`}>
                    <span className="fw-bold col-6 col-sm-5 col-md-3">
                      Name:
                    </span>
                    <span className="col-auto">{experimentSession.name}</span>
                  </div>
                )}
                {!(sampleSet?.site.included === false) && (
                  <div className={`${classes.cardField} card-text row`}>
                    <span className="fw-bold col-6 col-sm-5 col-md-3">
                      Site:
                    </span>
                    <span className="col-auto">{experimentSession.site}</span>
                  </div>
                )}
                {!(sampleSet?.date.included === false) && (
                  <div className={`${classes.cardField} card-text row`}>
                    <span className="fw-bold col-6 col-sm-5 col-md-3">
                      Date:
                    </span>
                    <span className="col-auto">{experimentSession.date}</span>
                  </div>
                )}
                {!(sampleSet?.species.included === false) && (
                  <div className={`${classes.cardField} card-text row`}>
                    <span className="fw-bold col-6 col-sm-5 col-md-3">
                      Species:
                    </span>
                    <span className="col-auto">
                      {experimentSession.species}
                    </span>
                  </div>
                )}
                {!(sampleSet?.notes.included === false) && (
                  <div className={`${classes.cardField} card-text row`}>
                    <span className="fw-bold col-6 col-sm-5 col-md-3">
                      Notes:
                    </span>
                    <span className="col-auto">{experimentSession.notes}</span>
                  </div>
                )}
                {experimentSession.addFields.map((key, index) => {
                  return !(sampleSet?.addFields[index]?.included === false) ? (
                    <div className={`${classes.cardField} card-text row`}>
                      <span className="fw-bold col-6 col-sm-5 col-md-3">
                        {key.fieldTitle}:
                      </span>
                      <span className="col-auto">{key.value}</span>
                    </div>
                  ) : null;
                })}
                <div className="row justify-content-end mb-3">
                  {experimentSession.columns &&
                    experimentSession.columns.map((key, index) => {
                      return !(
                        sampleSet?.columns[index]?.included === false
                      ) ? (
                        <div
                          className={`${classes.cardField} card-text col-auto d-flex flex-column`}
                        >
                          <span className={classes.fieldTitle}>
                            Column {index + 1}:
                          </span>
                          <span>{key}</span>
                        </div>
                      ) : null;
                    })}
                </div>
                <div className="row g-0">
                  <div className={`col-3 pe-0`}>
                    {experimentSession.rows &&
                      experimentSession.rows.map((key, index) => {
                        return !(sampleSet?.rows[index]?.included === false) ? (
                          <div className="row">
                            <p
                              className={`col-12 col-sm-auto ${classes.fieldTitle} m-0`}
                            >
                              Row {index + 1}:
                            </p>
                            <p className="col-12 col-sm-auto m-0">{key}</p>
                          </div>
                        ) : null;
                      })}
                  </div>

                  <div className={`col-9`}>
                    {signedUrlList?.map((value, index) => (
                      <img
                        src={value}
                        className="img-fluid img-thumbnail"
                      ></img>
                    ))}
                  </div>
                </div>
                {isEditor(user, experiment) && (
                  <div className="d-flex justify-content-between">
                    <Link
                      to={`/experiments/${experiment._id}/sessions/${experimentSession._id}/edit`}
                      className="btn btn-info m-2"
                    >
                      Edit Photo Information
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </Layout>
  );
};

export default ShowSessionPage;
