import classes from "./MainNavigation.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { getAuth, signOut } from "firebase/auth"; // Import Firebase auth utilities

function MainNavigation() {
  const { isUserLoggedIn, setIsUserLoggedIn, setUserToken, setFlash } =
    useAppContext();
  const navigate = useNavigate();
  const handleSignOut = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      // Remove authToken cookie through a backend endpoint
      // const response = await fetch(
      //   `${process.env.REACT_APP_API_URL}/api/sign-out`,
      //   {
      //     method: "POST",
      //     credentials: "include", // Important: Include cookies in the request
      //   }
      // );
      setIsUserLoggedIn(false);
      setUserToken("");
      navigate("/sign-in");

      // Set isUserLoggedIn to false
    } catch (error: any) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setFlash({ status: "failure", message: errorMessage });
    }
  };

  return (
    <header
      className={`navbar navbar-expand-lg navbar-light bg-light ${classes.header}`}
    >
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          iHotCoral
        </Link>

        {/* Hamburger button */}
        <button
          className={`navbar-toggler ${classes.hamburgerToggle}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar content */}
        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link">
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/experiments/my-experiments" className="nav-link">
                My Experiments
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/support" className="nav-link">
                FAQ
              </Link>
            </li>
            {!isUserLoggedIn && (
              <li className="nav-item">
                <Link to="/sign-in" className="nav-link">
                  Sign In
                </Link>
              </li>
            )}
            {isUserLoggedIn && (
              <li className="nav-item">
                <button
                  className="btn btn-sm btn-info m-2"
                  onClick={handleSignOut}
                >
                  Sign Out
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
}

export default MainNavigation;
