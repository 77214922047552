import Layout from "../components/layout/Layout";
import React, { FormEvent, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import classes from "./CreateExperimentPage.module.css";
import { ISampleSet, IExperiment, AddField } from "../types/experimentTypes";
import IExperimentSession from "../types/ExperimentSessionTypes";
import { useAppContext } from "../components/context/AppContext";
import { sampleSetTemplateData, addFieldShape } from "../helper/helper";
import { fetchWithRetry } from "../helper/axiosHelper";
import Picker from "../components/Picker";
// this page will also edit sample sets:
interface IResponseData {
  experiment: IExperiment;
}
interface ISampleSetResponse {
  sampleSet: ISampleSet;
}
interface ErrorWithResponse extends Error {
  response?: { data?: { error?: string } };
}
export interface IExperimentResponse {
  experiment: IExperiment;
  experimentSession: IExperimentSession;
}

const CreateExperimentPage: React.FC = () => {
  const { setError, setFlash, setIsLoading } = useAppContext();
  const { setAlert, userToken } = useAppContext();

  const params = useParams<{ id: string; sampleSetId: string }>();

  const id = params.id;
  const sampleSetId = params.sampleSetId;
  const [experiment, setExperiment] = useState<IExperiment | null>(null);
  const navigate = useNavigate();

  const [newDate, setNewDate] = useState<string | null>(null);
  const [newSite, setNewSite] = useState<string | null>(null);
  const [newSpecies, setNewSpecies] = useState<string | null>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const siteInputRef = useRef<HTMLInputElement>(null);
  const speciesInputRef = useRef<HTMLInputElement>(null);

  const [sampleSet, setSampleSet] = useState<ISampleSet>(sampleSetTemplateData);
  const sessionFieldKeyList: (keyof ISampleSet)[] = [
    // "sampleSetName",
    "name",
    "site",
    "date",
    "species",
    "notes",
    "colonies",
    // "columns",
    // "rows",
    "addFields",
  ];
  const AddFieldStringList: (keyof AddField)[] = [
    "included",
    "required",
    "value",
    "locked",
    "fieldTitle",
  ];

  const rowAndColumnsArray: (keyof ISampleSet)[] = [
    "columns",
    "rows",
    "addFields",
  ];

  type SessionFieldKeys =
    // | "sampleSetName"
    | "name"
    | "site"
    | "date"
    | "species"
    | "notes"
    | "columns"
    | "rows"
    | "addFields"
    | "colonies";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchWithRetry<IResponseData>(
          `/api/experiments/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
            withCredentials: true, // Include cookies in the request
          }
        );
        if (result.data.experiment) {
          setExperiment(result.data.experiment);
        }

        // setSampleSet((prev) => ({
        //   ...prev,
        //   date: {
        //     ...prev.date,
        //     value: prev?.date?.value ?? "",
        //   },
        //   site: {
        //     ...prev.site,
        //     value: prev?.site?.value ?? "",
        //   },
        // }));
      } catch (error: any) {
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setFlash({ status: "failure", message: errorMessage });
      }
    };

    fetchData();
  }, [id, userToken]);

  useEffect(() => {
    if (sampleSetId && id) {
      const fetchData = async () => {
        try {
          const result = await fetchWithRetry<ISampleSetResponse>(
            `/api/experiments/${id}/sample-sets/${sampleSetId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
              withCredentials: true, // Include cookies in the request
            }
          );
          if (result.data.sampleSet) {
            setSampleSet(result.data.sampleSet);
          }
        } catch (error: any) {
          let errorMessage = error.message;
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            errorMessage = error.response.data.error;
          }
          setFlash({ status: "failure", message: errorMessage });
        }
      };

      fetchData();
    }
  }, [sampleSetId, id]);

  const updateExperimentListField = async (listName, newValue) => {
    try {
      let newList = experiment[listName] || [];
      newList = [...newList, newValue];

      const result = await axios.put<IExperimentResponse>(
        `${process.env.REACT_APP_API_URL}/api/experiments/${id}`,
        { [listName]: newList },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );

      if (result.data.experiment) {
        setExperiment(result.data.experiment);
        setSampleSet((prev) => ({
          ...prev,
          [listName.split("List")[0]]: {
            included: prev[listName.split("List")[0]].included,
            value: newValue,
            locked: prev[listName.split("List")[0]].locked,
            required: prev[listName.split("List")[0]].required,
          },
        }));
      }
    } catch (error: any) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setError({ title: "There was an error!", message: errorMessage });
    }
  };
  useEffect(() => {
    if (experiment && newSite && id) {
      updateExperimentListField("siteList", newSite);
    }
  }, [newSite]);

  useEffect(() => {
    if (experiment && newSpecies && id) {
      updateExperimentListField("speciesList", newSpecies);
    }
  }, [newSpecies]);

  useEffect(() => {
    if (experiment && newDate && id) {
      updateExperimentListField("dateList", newDate);
    }
  }, [newDate]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName: SessionFieldKeys = event.target.name as SessionFieldKeys;

    let value = event.target.value;

    if (event.target.value === "add-new-date") {
      showAlertDatePicker();
      value = ""; // Reset the value to an empty string

      return;
    } else if (event.target.value === "add-new-site") {
      showAlertSitePicker();
      value = ""; // Reset the value to an empty string

      return;
    } else if (event.target.value === "add-new-species") {
      showAlertSpeciesPicker();
      value = ""; // Reset the value to an empty string

      return;
    }

    if (sampleSet[fieldName] === undefined) return; // add this line

    if (event.target.id.includes("included")) {
      if (typeof sampleSet[fieldName] === "object") {
        setSampleSet({
          ...sampleSet,
          [fieldName]: {
            ...sampleSet[fieldName], // Note that we're using fieldName here, not sampleSet.name
            included: event.target.checked,
          },
        });
      }
    } else if (event.target.id.includes("required")) {
      setSampleSet({
        ...sampleSet,
        [fieldName]: {
          ...sampleSet[fieldName], // Note that we're using fieldName here, not sampleSet.name
          required: event.target.checked,
        },
      });
    } else if (event.target.id.includes("value")) {
      setSampleSet({
        ...sampleSet,
        [fieldName]: {
          ...sampleSet[fieldName], // Note that we're using fieldName here, not sampleSet.name
          value: event.target.value,
        },
      });
    } else if (event.target.id.includes("locked")) {
      setSampleSet({
        ...sampleSet,
        [fieldName]: {
          ...sampleSet[fieldName], // Note that we're using fieldName here, not sampleSet.name
          locked: event.target.checked,
        },
      });
    }
  };

  const showAlertDatePicker = () => {
    setAlert(
      Picker({
        label: "Date",
        inputRef: dateInputRef,
        experimentList: experiment.dateList,
        setNewItem: setNewDate,
        setError,
        setAlert,
      })
    );
  };

  const showAlertSitePicker = () => {
    setAlert(
      Picker({
        label: "Site",
        inputRef: siteInputRef,
        experimentList: experiment.siteList,
        setNewItem: setNewSite,
        setError,
        setAlert,
      })
    );
  };

  const showAlertSpeciesPicker = () => {
    setAlert(
      Picker({
        label: "Species",
        inputRef: speciesInputRef,
        experimentList: experiment.speciesList,
        setNewItem: setNewSpecies,
        setError,
        setAlert,
      })
    );
  };

  const handleRowColAddDelete = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const buttonId = event.currentTarget.id;
    if (buttonId === "columns-add-button") {
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        columns: [...prevSampleSet.columns, addFieldShape], // Update only the first column
      }));
    } else if (buttonId === "columns-delete-button") {
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        columns: [...prevSampleSet.columns.slice(0, -1)], // Update only the first column
      }));
    } else if (buttonId === "rows-delete-button") {
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        rows: [...prevSampleSet.rows.slice(0, -1)], // Update only the first column
      }));
    } else if (buttonId === "rows-add-button") {
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        rows: [...prevSampleSet.rows, addFieldShape], // Update only the first column
      }));
    } else if (buttonId === "addFields-add-button") {
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        addFields: [...prevSampleSet.addFields, addFieldShape], // Update only the first column
      }));
    } else if (buttonId === "addFields-delete-button") {
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        addFields: [...prevSampleSet.addFields.slice(0, -1)], // Update only the first column
      }));
    }
  };
  const handleColandRowChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (typeof index === "string") index = parseInt(index);
    const fieldName: string = event.target.name;

    let subField = "blank";
    if (event.target.id.includes("value")) {
      subField = "value";
    } else if (event.target.id.includes("fieldTitle")) {
      subField = "fieldTitle";
    } else if (event.target.id.includes("included")) {
      subField = "included";
    } else if (event.target.id.includes("required")) {
      subField = "required";
    } else if (event.target.id.includes("locked")) {
      subField = "locked";
    } else {
      return;
    }
    if (typeof index !== "number") {
      return;
    }
    if (
      typeof fieldName !== "string" ||
      // !AddFieldStringList.includes(fieldName as keyof AddField) ||
      !sampleSet.hasOwnProperty(fieldName)
    ) {
      return;
    }
    if (event.target.type === "checkbox") {
      let newElement: any = { ...sampleSet[fieldName][index] }; // Deep copy of columns[0]
      if (newElement) {
        newElement[subField] = event.target.checked; // update fieldTitle with the input value
      }
      let updatedContent = sampleSet[fieldName];
      updatedContent[index] = newElement;

      // Set the state using spread operator to maintain existing values
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        fieldName: updatedContent,
      }));
    } else if (fieldName === "rows" || fieldName === "columns" || "addFields") {
      let newElement: any = { ...sampleSet[fieldName][index] }; // Deep copy of columns[0]
      if (newElement) {
        newElement[subField] = event.target.value; // update fieldTitle with the input value
      }
      let updatedContent = sampleSet[fieldName];
      updatedContent[index] = newElement;

      // Set the state using spread operator to maintain existing values
      setSampleSet((prevSampleSet) => ({
        ...prevSampleSet,
        fieldName: updatedContent,
      }));
    } else {
      console.log("type of fieldName is not valid");
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!sampleSetId) {
      setIsLoading(true);

      // eg we are creating a new sample set:
      try {
        const response = await axios.post<ISampleSet>(
          `${process.env.REACT_APP_API_URL}/api/experiments/${id}/sample-sets`,
          sampleSet,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },

            withCredentials: true, // Include cookies in the request
          }
        );

        if (response.status === 201) {
          setIsLoading(false);
          navigate(`/experiments/${id}`);
          setFlash({
            status: "success",
            message: "Successfully created a new template",
          });
        }
      } catch (error: any) {
        setIsLoading(false);
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setError({ title: "There was an error!", message: errorMessage });
      }
    } else {
      setIsLoading(true);
      try {
        const response = await axios.put<ISampleSet>(
          `${process.env.REACT_APP_API_URL}/api/experiments/${id}/sample-sets/${sampleSetId}`,
          sampleSet,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },

            withCredentials: true, // Include cookies in the request
          }
        );

        if (response.status === 200) {
          setIsLoading(false);
          navigate(`/experiments/${id}`);
          setFlash({
            status: "success",
            message: "Successfully updated a template",
          });
        }
      } catch (error: any) {
        setIsLoading(false);
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setError({ title: "There was an error!", message: errorMessage });
      }
    }
  };

  const updateSampleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      const newName = event.target.value;
      setSampleSet((prev) => ({ ...prev, sampleSetName: newName }));
    }
  };
  const renderRowAndColumn = (
    key,
    index,
    index2,
    sampleSet,
    handleColandRowChange,
    classes
  ) => (
    // index2 is the index of the column. Index 1 indicates if this is a row or col.
    <div key={`${key}-${index2}`}>
      <div className="row">
        {" "}
        <h6>
          {key === "columns"
            ? "Column"
            : key === "rows"
            ? "Row"
            : "Additional Fields"}{" "}
          {index2 + 1}:
        </h6>
      </div>
      <div className="row mb-3">
        <div className="form-check col-md mb-1">
          {key === "addFields" && (
            <div className="d-flex flex-column">
              <label htmlFor={`${key}-${index2}-fieldTitle`}>
                {" "}
                Field Title <span> *</span>
              </label>
              <input
                id={`${key}-${index2}-fieldTitle`}
                name={key}
                required
                className={`form-control-sm`}
                value={
                  // Check if sampleSet[key] is defined
                  sampleSet[key]
                    ? // Check if it's an array
                      Array.isArray(sampleSet[key] as AddField[])
                      ? // Safely access the array element at index 0
                        sampleSet[key] && (sampleSet[key][index2] as any)
                        ? // Check if it's an object containing "fieldTitle"
                          "fieldTitle" in sampleSet[key][index2]
                          ? // Check if fieldTitle is a string
                            typeof sampleSet[key][index2].fieldTitle ===
                            "string"
                            ? // If all checks pass, use it
                              sampleSet[key][index2].fieldTitle
                            : ""
                          : ""
                        : ""
                      : ""
                    : ""
                }
                onChange={(e) => handleColandRowChange(e, index2)}
              ></input>
            </div>
          )}
          <div className="d-flex flex-column">
            <label htmlFor={`${key}-${index2}-value`}>Default Value</label>
            <input
              id={`${key}-${index2}-value`}
              name={key}
              className={`form-control-sm`}
              value={
                // Check if sampleSet[key] is defined
                sampleSet[key]
                  ? // Check if it's an array
                    Array.isArray(sampleSet[key] as AddField[])
                    ? // Safely access the array element at index 0
                      sampleSet[key] && (sampleSet[key][index2] as any)
                      ? // Check if it's an object containing "value"
                        "value" in sampleSet[key][index2]
                        ? // Check if value is a string
                          typeof sampleSet[key][index2].value === "string"
                          ? // If all checks pass, use it
                            sampleSet[key][index2].value
                          : ""
                        : ""
                      : ""
                    : ""
                  : ""
              }
              onChange={(e) => handleColandRowChange(e, index2)}
            ></input>
          </div>
        </div>
        <div className="form-check col-md mb-1">
          <label htmlFor={`${key}-${index2}-included`}> Included?</label>
          <input
            type="checkbox"
            id={`${key}-${index2}-included`}
            name={key}
            className={`${classes.checkbox} form-check-input`}
            checked={
              sampleSet[key] // First make sure that this value is not undefined
                ? typeof (sampleSet[key] as any) === "object" // then check its type
                  ? "included" in (sampleSet[key][index2] as any) // then check if 'value' exists
                    ? typeof (sampleSet[key][index2] as any).included ===
                      "boolean" // then check its type
                      ? (sampleSet[key][index2] as any).included // if it's a boolean, use it
                      : false // if it's not a boolean, use false
                    : false // if 'value' doesn't exist on the object, use false
                  : false // if sampleSet[key] is undefined, use false
                : false
            }
            onChange={(e) => handleColandRowChange(e, index2)}
          ></input>
        </div>
        <div className="form-check col-md mb-1">
          <label htmlFor={`${key}-${index2}-required`}> Required?</label>
          <input
            type="checkbox"
            id={`${key}-${index2}-required`}
            name={key}
            className={`${classes.checkbox} form-check-input`}
            checked={
              sampleSet[key] // First make sure that this value is not undefined
                ? typeof (sampleSet[key] as any) === "object" // then check its type
                  ? "required" in (sampleSet[key][index2] as any) // then check if 'value' exists
                    ? typeof (sampleSet[key][index2] as any).required ===
                      "boolean" // then check its type
                      ? (sampleSet[key][index2] as any).required // if it's a boolean, use it
                      : false // if it's not a boolean, use false
                    : false // if 'value' doesn't exist on the object, use false
                  : false // if sampleSet[key] is undefined, use false
                : false
            }
            onChange={(e) => handleColandRowChange(e, index2)}
          ></input>
        </div>

        <div className="form-check col-md mb-1">
          <label htmlFor={`${key}-${index2}-locked`}>
            {" "}
            Lock value to default value
          </label>
          <input
            type="checkbox"
            id={`${key}-${index2}-locked`}
            name={key}
            className={`${classes.checkbox} form-check-input`}
            checked={
              sampleSet[key] // First make sure that this value is not undefined
                ? typeof (sampleSet[key] as any) === "object" // then check its type
                  ? "locked" in (sampleSet[key][index2] as any) // then check if 'value' exists
                    ? typeof (sampleSet[key][index2] as any).locked ===
                      "boolean" // then check its type
                      ? (sampleSet[key][index2] as any).locked // if it's a string, use it
                      : false // if it's not a string, leave blank.
                    : false // if 'value' doesn't exist on the object, use blank
                  : false // if sampleSet[key] is undefined, use blank
                : false
            }
            onChange={(e) => handleColandRowChange(e, index2)}
          ></input>
        </div>
      </div>
    </div>
  );
  const renderRow = ({ key, classes, sampleSet, handleCheckboxChange }) => {
    return (
      <div key={key} className="row mb-3">
        <div className="col-md mb-1 col-md-2">
          <h5 className={`${classes.checkboxLabel} form-check-label`}>
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </h5>
          {key.includes("addField") && (
            <div className="form-check">
              <label htmlFor={`${key}-fieldTitle`}> Field Title</label>
              {/* if we are NOT "site" then do this. Site gets select/options: */}
              {!(key === "site") && (
                <input
                  id={`${key}-fieldTitle`}
                  name={key}
                  className={``}
                  value={
                    sampleSet[key] // First make sure that this value is not undefined
                      ? typeof (sampleSet[key] as any) === "object" // then check its type
                        ? "fieldTitle" in (sampleSet[key] as any) // then check if 'value' exists
                          ? typeof (sampleSet[key] as any).value === "string" // then check its type
                            ? (sampleSet[key] as any).value // if it's a string, use it
                            : "" // if it's not a string, leave blank.
                          : "" // if 'value' doesn't exist on the object, use blank
                        : "" // if sampleSet[key] is undefined, use blank
                      : ""
                  }
                  onChange={handleCheckboxChange}
                ></input>
              )}
            </div>
          )}
        </div>

        <div className="form-check col-md col-md-2 mb-1">
          <label htmlFor={`${key}-included`}> Included?</label>
          <input
            type="checkbox"
            id={`${key}-included`}
            name={key}
            className={`${classes.checkbox} form-check-input`}
            checked={
              sampleSet[key] // First make sure that this value is not undefined
                ? typeof (sampleSet[key] as any) === "object" // then check its type
                  ? "included" in (sampleSet[key] as any) // then check if 'value' exists
                    ? typeof (sampleSet[key] as any).included === "boolean" // then check its type
                      ? (sampleSet[key] as any).included // if it's a boolean, use it
                      : false // if it's not a boolean, use false
                    : false // if 'value' doesn't exist on the object, use false
                  : false // if sampleSet[key] is undefined, use false
                : false
            }
            onChange={handleCheckboxChange}
          ></input>
        </div>
        <div className="form-check col-md col-md-2 mb-1">
          <label htmlFor={`${key}-required`}> Required?</label>
          <input
            type="checkbox"
            id={`${key}-required`}
            name={key}
            className={`${classes.checkbox} form-check-input`}
            checked={
              sampleSet[key] // First make sure that this value is not undefined
                ? typeof (sampleSet[key] as any) === "object" // then check its type
                  ? "required" in (sampleSet[key] as any) // then check if 'value' exists
                    ? typeof (sampleSet[key] as any).required === "boolean" // then check its type
                      ? (sampleSet[key] as any).required // if it's a boolean, use it
                      : false // if it's not a boolean, use false
                    : false // if 'value' doesn't exist on the object, use false
                  : false // if sampleSet[key] is undefined, use false
                : false
            }
            onChange={handleCheckboxChange}
          ></input>
        </div>
        <div className="form-check col-md col-md-3 mb-1">
          <div className="d-flex flex-column">
            <label htmlFor={`${key}-value`}>
              {" "}
              Default Value {key === "date" ? "(year, month, day)" : ""}
            </label>
            {(key === "name" || key === "notes" || key === "colonies") && (
              <input
                id={`${key}-value`}
                name={key}
                className={`form-control-sm`}
                type={key === "date" ? "date" : "text"}
                value={
                  sampleSet[key] // First make sure that this value is not undefined
                    ? typeof (sampleSet[key] as any) === "object" // then check its type
                      ? "value" in (sampleSet[key] as any) // then check if 'value' exists
                        ? typeof (sampleSet[key] as any).value === "string" // then check its type
                          ? (sampleSet[key] as any).value // if it's a string, use it
                          : "" // if it's not a string, leave blank.
                        : "" // if 'value' doesn't exist on the object, use blank
                      : "" // if sampleSet[key] is undefined, use blank
                    : ""
                }
                onChange={handleCheckboxChange}
              ></input>
            )}
            {(key === "site" || key === "date" || key === "species") && (
              <select
                id={`${key}-value`}
                name={key}
                className={`form-control w-100`}
                value={
                  sampleSet[key] // First make sure that this value is not undefined
                    ? typeof (sampleSet[key] as any) === "object" // then check its type
                      ? "value" in (sampleSet[key] as any) // then check if 'value' exists
                        ? typeof (sampleSet[key] as any).value === "string" // then check its type
                          ? (sampleSet[key] as any).value // if it's a string, use it
                          : "" // if it's not a string, leave blank.
                        : "" // if 'value' doesn't exist on the object, use blank
                      : "" // if sampleSet[key] is undefined, use blank
                    : ""
                }
                onChange={handleCheckboxChange}
              >
                <option></option>
                {key === "site"
                  ? experiment?.siteList.map((val, index) => (
                      <option key={index}> {val}</option>
                    ))
                  : key === "date"
                  ? experiment?.dateList.map((val2, index) => (
                      <option key={index}>{val2}</option>
                    ))
                  : key === "species"
                  ? experiment?.speciesList.map((val3, index) => (
                      <option key={index}>{val3}</option>
                    ))
                  : ""}

                {key === "date" && (
                  <option value="add-new-date">Add a new date</option>
                )}
                {key === "site" && (
                  <option value="add-new-site">Add a new site</option>
                )}
                {key === "species" && (
                  <option value="add-new-species">Add a new species</option>
                )}
              </select>
            )}
          </div>
        </div>
        <div className="form-check col-md col-md-3 mb-1">
          <label htmlFor={`${key}-locked`}> Lock value to default value</label>
          <input
            type="checkbox"
            id={`${key}-locked`}
            name={key}
            className={`${classes.checkbox} form-check-input`}
            checked={
              sampleSet[key] // First make sure that this value is not undefined
                ? typeof (sampleSet[key] as any) === "object" // then check its type
                  ? "locked" in (sampleSet[key] as any) // then check if 'value' exists
                    ? typeof (sampleSet[key] as any).locked === "boolean" // then check its type
                      ? (sampleSet[key] as any).locked // if it's a string, use it
                      : false // if it's not a string, leave blank.
                    : false // if 'value' doesn't exist on the object, use blank
                  : false // if sampleSet[key] is undefined, use blank
                : false
            }
            onChange={handleCheckboxChange}
          ></input>
        </div>
      </div>
    );
  };

  const handleDeleteTemplate = async () => {
    if (id && sampleSetId) {
      try {
        setIsLoading(true);
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/experiments/${id}/sample-sets/${sampleSetId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },

            withCredentials: true, // Include cookies in the request
          }
        );
        // 204 meaning content is deleted:
        if (response.status === 204) {
          setIsLoading(false);
          navigate(`/experiments/${id}`);
          setFlash({
            status: "success",
            message: "Template deleted successfully",
          });
          setAlert(null);
        }
      } catch (error: any) {
        setIsLoading(false);
        let errorMessage = error.message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        setError({ title: "There was an error!", message: errorMessage });
      }
    }
  };

  const setDeleteAlert = () => {
    const alertJSX = (
      <div>
        <p>Are you sure you want to delete this template?</p>
        <button
          className="btn btn-danger btn-sm m-2"
          onClick={handleDeleteTemplate}
        >
          Yes, I am sure.
        </button>
      </div>
    );

    setAlert(alertJSX);
  };

  return (
    <Layout>
      {id && (
        <Link to={`/experiments/${id}`} className="m-2">
          &larr; Back to Experiment
        </Link>
      )}

      {sampleSetId ? <h1>Edit Template</h1> : <h1>Create Template</h1>}
      <div className="d-flex justify-content-end mb-3">
        {experiment && sampleSetId && (
          <button
            onClick={setDeleteAlert}
            className="btn btn-sm btn-danger m-2 col-auto"
          >
            Delete this Template
          </button>
        )}
      </div>

      <form onSubmit={handleSubmit} className={classes.form}>
        <div className="row mb-3">
          <label htmlFor="sampleSetTitle" className="col-sm-2 col-form-label">
            Template Title <span>*</span>
          </label>
          <div className="col-sm-10">
            <input
              className="form-control"
              id="sampleSetTitle"
              name="sampleSetTitle"
              required
              value={sampleSet.sampleSetName}
              onChange={updateSampleSetName}
            />
          </div>
        </div>
        {sessionFieldKeyList.map((key) => (
          <div key={key}>
            {!key.includes("addField") &&
              renderRow({ key, classes, sampleSet, handleCheckboxChange })}
          </div>
        ))}
        {/* adding columns and rows */}
        {rowAndColumnsArray.map((key, index) => (
          <div key={`${key}-parent`}>
            <div className="row mb-3">
              <h5 className={`${classes.checkboxLabel} form-check-label`}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </h5>
            </div>
            {key === "columns" &&
              sampleSet.columns.map((key2, index2) => {
                return (
                  <div key={index2}>
                    {renderRowAndColumn(
                      key,
                      index,
                      index2,
                      sampleSet,
                      handleColandRowChange,
                      classes
                    )}
                  </div>
                );
              })}
            {key === "rows" &&
              sampleSet.rows.map((key2, index2) => {
                return (
                  <div key={index2}>
                    {renderRowAndColumn(
                      key,
                      index,
                      index2,
                      sampleSet,
                      handleColandRowChange,
                      classes
                    )}
                  </div>
                );
              })}
            {key === "addFields" &&
              sampleSet.addFields.map((key2, index2) => {
                return (
                  <div>
                    {renderRowAndColumn(
                      key,
                      index,
                      index2,
                      sampleSet,
                      handleColandRowChange,
                      classes
                    )}
                  </div>
                );
              })}

            <button
              className="btn btn-info m-2"
              type="button"
              id={`${key}-add-button`}
              onClick={handleRowColAddDelete}
            >
              add a {key === "columns" ? "column" : "row"}
            </button>
            <button
              type="button"
              className="btn btn-danger m-2"
              id={`${key}-delete-button`}
              onClick={handleRowColAddDelete}
            >
              remove the last {key === "columns" ? "column" : "row"}
            </button>
          </div>
        ))}
        <br />
        <button className="btn btn-primary">Save</button>
      </form>
    </Layout>
  );
};

export default CreateExperimentPage;
