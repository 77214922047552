import React, { ReactNode, Dispatch, SetStateAction, RefObject } from "react";
import { useAppContext } from "../components/context/AppContext";
interface ErrorType {
  message: string;
  title: string;
}

interface PickerProps {
  label: string;
  inputRef: React.RefObject<HTMLInputElement>;
  experimentList: string[];
  setNewItem: React.Dispatch<React.SetStateAction<string>>;
  setError: (error: ErrorType) => void;
  setAlert: Dispatch<SetStateAction<ReactNode | null>>; // considering the alert could also be set to null
}

const Picker: React.FC<PickerProps> = ({
  label,
  inputRef,
  experimentList,
  setNewItem,
  setError,
  setAlert,
}) => {
  const handleError = ({ message, title }) => {
    setError({ message, title });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputRef.current && inputRef.current.value) {
      if (experimentList.includes(inputRef.current.value)) {
        setAlert(null);
        handleError({
          message: "That value exists already in the list of options.",
          title: "Invalid Entry",
        });
        return;
      }
      setNewItem(inputRef.current.value);
      setAlert(null);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor={`${label.toLowerCase()}-input`}>New {label}: </label>
        <input
          id={`${label.toLowerCase()}-input`}
          className="form-control"
          ref={inputRef}
          type={label === "Date" ? "date" : "text"}
        />
        <button className="btn btn-success m-2">Submit</button>
      </form>
    </div>
  );
};

export default Picker;
