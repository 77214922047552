import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import CreateSessionPage from "./pages/CreateSessionPage";
import CreateExperimentPage from "./pages/CreateExperimentPage";
import ShowExperimentPage from "./pages/ShowExperimentPage";
import ShowMyExperimentsPage from "./pages/ShowMyExperimentsPage";
import "./colors.css";
import EditExperimentPage from "./pages/EditExperimentPage";
import ShowSessionPage from "./pages/ShowSessionPage";
import SupportPage from "./pages/SupportPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

import { AppProvider } from "./components/context/AppContext"; // Make sure to import your AppProvider
import CreateSampleSet from "./pages/CreateSampleSet";
const App: React.FC = function () {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />

          <Route
            path="/experiments/create"
            element={<CreateExperimentPage />}
          />
          <Route
            path="/experiments/my-experiments"
            element={<ShowMyExperimentsPage />}
          />
          <Route path="/experiments/:id" element={<ShowExperimentPage />} />
          <Route
            path="/experiments/:id/edit"
            element={<EditExperimentPage />}
          />
          <Route
            path="/experiments/:id/sessions/add"
            element={<CreateSessionPage />}
          />
          <Route
            path="/experiments/:id/sessions/:sessionId"
            element={<ShowSessionPage />}
          />
          <Route
            path="/experiments/:id/sessions/:sessionId/edit"
            element={<CreateSessionPage />}
          />
          <Route
            path="/experiments/:id/sample-sets/add"
            element={<CreateSampleSet />}
          />
          <Route
            path="/experiments/:id/sample-sets/:sampleSetId"
            element={<CreateSampleSet />}
          />
        </Routes>
      </Router>
    </AppProvider>
  );
};

export default App;
