import React, { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  getIdToken,
  sendPasswordResetEmail,
} from "firebase/auth";
import { app } from "../firebase";
import styles from "./SignIn.module.css";
import { useAppContext } from "./context/AppContext"; // Adjust path accordingly
import { useNavigate, Link } from "react-router-dom";

const auth = getAuth(app);

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { setUserToken, userToken, setIsUserLoggedIn, setFlash } =
    useAppContext(); // Destructure setUserToken from context
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    try {
      setError(null);
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link has been sent to your email address.");
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      if (errorMessage.includes("invalid-email")) {
        errorMessage = "Please enter a valid email";
      }
      if (errorMessage.includes("user-not-found")) {
        errorMessage = "That user was not found";
      }

      setFlash({ status: "failure", message: errorMessage });
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Reset Password</h2>
      <div className={styles.field}>
        <label className={styles.label}>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
        </label>
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <button onClick={handleForgotPassword} className={styles.button}>
        Send email for password reset
      </button>
    </div>
  );
}

export default ForgotPassword;
