import Layout from "../components/layout/Layout";
import { useEffect, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { IExperiment } from "../types/experimentTypes";
import IExperimentSession from "../types/ExperimentSessionTypes";
import ForgotPassword from "../components/ForgotPassword";
import { useAppContext } from "../components/context/AppContext";

export interface IExperimentResponse {
  experiment: IExperiment;
  filteredSessions: IExperimentSession[];
}
const SupportPage: React.FC = () => {
  const { isUserLoggedIn } = useAppContext();
  return (
    <Layout>
      {isUserLoggedIn && (
        <Link to={`/experiments/my-experiments`} className="m-2 col-auto">
          &larr; View My Experiments
        </Link>
      )}
      <br />
      <Link to={`/sign-in`} className="m-2 col-auto">
        &larr; Back to Sign in
      </Link>
      {!isUserLoggedIn && <ForgotPassword></ForgotPassword>}
    </Layout>
  );
};

export default SupportPage;
