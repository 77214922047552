import Layout from "../components/layout/Layout";
import React, { FormEvent, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import classes from "./CreateExperimentPage.module.css";
import { ISampleSet, IExperiment, AddField } from "../types/experimentTypes";
import { useAppContext } from "../components/context/AppContext";
import EditExperimentPage from "./EditExperimentPage";
import { sampleSetTemplateData } from "../helper/helper";

const addFieldShape = {
  included: false,
  required: false,
  value: "",
  locked: false,
  fieldTitle: "",
};
const CreateExperimentPage: React.FC = () => {
  const { setError, setIsLoading } = useAppContext();
  const navigate = useNavigate();
  const [siteList, setSiteList] = useState<string[]>([]);
  const [dateList, setDateList] = useState<string[]>([]);
  const [speciesList, setSpeciesList] = useState<string[]>([]);

  const { isUserLoggedIn, userToken } = useAppContext();

  const [sampleSets, setSampleSet] = useState<ISampleSet>(
    sampleSetTemplateData
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Get form data
    const target = event.target as typeof event.target & {
      title: { value: string };
      description: { value: string };
      guidelines: { value: string };
      location: { value: string };
      // dateListLocked: { checked: boolean };
      // siteListLocked: { checked: boolean };
    };

    // Extract the form data
    const title = target.title.value;
    const description = target.description.value;
    const guidelines = target.guidelines.value;
    const location = target.location.value;
    const dateListLocked = false;
    const siteListLocked = false;
    const speciesListLocked = false;
    // fix me-maybe take this out...

    try {
      if (!isUserLoggedIn) {
        throw new Error("user is not logged in");
      }
      setIsLoading(true);
      const response = await axios.post<IExperiment>(
        `${process.env.REACT_APP_API_URL}/api/experiments`,
        {
          title,
          description,
          guidelines,
          location,
          sampleSets,
          siteList,
          dateList,
          speciesList,
          dateListLocked,
          siteListLocked,
          speciesListLocked,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

          withCredentials: true, // Include cookies in the request
        }
      );

      if (response.status === 201) {
        setIsLoading(false);
        navigate(`/experiments/${response.data._id}`);
      }
    } catch (error: any) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setIsLoading(false);
      setError({ title: "There was an error!", message: errorMessage });
    }
  };

  return (
    <Layout>
      <Link to={`/experiments/my-experiments`} className="m-2 col-auto">
        &larr; Back to My Experiments
      </Link>
      <h1>Create Experiment</h1>
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className="row mb-3">
          <label htmlFor="title" className="col-sm-2 col-form-label">
            Title<span className="requiredField">*</span>
          </label>
          <div className="col-sm-10">
            <input className="form-control" id="title" name="title" required />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="description" className="col-sm-2 col-form-label">
            Description
          </label>
          <div className="col-sm-10">
            <input
              className="form-control"
              id="description"
              name="description"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="guidelines" className="col-sm-2 col-form-label">
            Guidelines
          </label>
          <div className="col-sm-10">
            <textarea
              className="form-control"
              id="guidelines"
              name="guidelines"
              placeholder="Is there anything people should keep in mind when they upload photos to this experiment?"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="location" className="col-sm-2 col-form-label">
            Location
          </label>
          <div className="col-sm-10">
            <input className="form-control" id="location" name="location" />
          </div>
        </div>

        {/* <div className="row mb-3">
          <p>
            Your experiment data will be organized by the sites that you select,
            so be sure to list all the relevant sites.
          </p>
          <label htmlFor="siteList" className="col-sm-2 col-form-label">
            Site List
          </label>
          <div className="col-sm-10">
            {siteList.map((site, index) => (
              <div className="row g-3 align-items-center" key={index}>
                <div className="col-auto">
                  <input
                    className="form-control"
                    value={site}
                    onChange={(e) => handleSiteChange(e, index)}
                  />
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-sm btn-danger m-2"
                    type="button"
                    onClick={() => removeSite(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <button
              className="btn btn-success m-2"
              type="button"
              onClick={addSite}
            >
              Add Site
            </button>
          </div>
        </div> */}
        {/* <div className="row mb-3">
          <label htmlFor="siteList" className="col-sm-2 col-form-label">
            Lock the site options to the current list
          </label>
          <div className="col-sm-10  form-check">
            <input
              className="form-check-input ms-1"
              type="checkbox"
              name="siteListLocked"
            />
          </div>
        </div> */}

        {/* New Date List Section */}
        {/* <p>
          Your experiment data will also be organized by the dates that you
          select.
        </p>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Date List</label>
          <div className="col-sm-10">
            {dateList.map((date, index) => (
              <div key={index} className="row g-3 align-items-center">
                <div className="col-auto">
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    onChange={(e) => updateDate(index, e.target.value)}
                  />
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-sm btn-danger m-2"
                    type="button"
                    onClick={() => removeDate(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <button
              className="btn btn-success m-2"
              type="button"
              onClick={addDate}
            >
              Add Date
            </button>
          </div>
        </div> */}

        {/* <div className="row mb-3">
          <label htmlFor="siteList" className="col-sm-2 col-form-label">
            Lock the Date options to the current list
          </label>
          <div className="col-sm-10  form-check">
            <input
              className="form-check-input ms-1"
              type="checkbox"
              name="dateListLocked"
            />
          </div>
        </div> */}

        <br />
        <button className="btn btn-primary">Submit Data</button>
      </form>
    </Layout>
  );
};

export default CreateExperimentPage;
