import instance from "./axiosInstance";
import { getAuth, getIdToken } from "firebase/auth";
const auth = getAuth(); // Assuming you've initialized your firebase app elsewhere

export const fetchWithRetry = async <T>(url, options, retries = 3) => {
  try {
    const response = await instance(url, options);
    return response;
  } catch (error) {
    if (retries > 0) {
      // Here, you could potentially refresh the token and update the options.headers.Authorization
      if (error?.message?.includes("firebase")) {
        try {
          const currentUser = auth.currentUser;

          if (currentUser) {
            const newToken = await currentUser.getIdToken(true); // Force refresh to get a new token
            options.headers.Authorization = `Bearer ${newToken}`; // Update the options with the new token
          } else {
            console.error("No user signed in to refresh token");
          }
        } catch (tokenError) {
          console.error("Error refreshing Firebase token", tokenError);
          throw tokenError; // If there's an issue getting a new token, you might want to stop and throw here
        }
      }
      return await fetchWithRetry(url, options, retries - 1);
    }
    let errorMessage = error.message;
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error;
    }

    throw new Error(errorMessage);
  }
};
