import React, { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  getIdToken,
  sendPasswordResetEmail,
} from "firebase/auth";
import { app } from "../firebase";
import styles from "./SignIn.module.css";
import { useAppContext } from "./context/AppContext"; // Adjust path accordingly
import { useNavigate, Link } from "react-router-dom";

const auth = getAuth(app);

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { setUserToken, userToken, setIsUserLoggedIn, setFlash } =
    useAppContext(); // Destructure setUserToken from context
  const navigate = useNavigate();
  const handleSignIn = async () => {
    try {
      setError(null);
      await signInWithEmailAndPassword(auth, email, password);

      // Getting the token after successful sign-in
      const user = await auth.currentUser;
      if (user && user.accessToken) {
        setUserToken(user.accessToken);
        setIsUserLoggedIn(true);
        navigate(`/experiments/my-experiments`);
      } else {
        throw new Error("user or id not found.");
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      if (errorMessage.includes("invalid-email")) {
        errorMessage = "Please enter a valid email ";
      }
      if (errorMessage.includes("missing-password")) {
        errorMessage = "Please enter your password ";
      }
      if (
        errorMessage.includes("wrong-password") ||
        errorMessage.includes("user-not-found")
      ) {
        errorMessage = "Your email/password combination was not correct";
      }

      setFlash({ status: "failure", message: errorMessage });
    }
  };
  const handleForgotPassword = async () => {
    try {
      setError(null);
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link has been sent to your email address.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setError(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Sign In</h2>
      <div className={styles.field}>
        <label className={styles.label}>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
        </label>
      </div>
      <div className={styles.field}>
        <label className={styles.label}>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />
        </label>
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <button onClick={handleSignIn} className={styles.button}>
        Sign In
      </button>
      <br />
      <div className="mt-4">
        <p className="text-end">
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
      </div>
      <p className="mt-2 text-end">
        Don't have an account? <Link to="/sign-up">Sign up!</Link>
      </p>
    </div>
  );
}

export default SignIn;
